import React, { ReactElement, useState, useCallback, useMemo, useEffect } from "react";
import { useDeepCompareMemo } from "use-deep-compare";
import { Select as KittenSelect, SelectItem, IndexPath } from "@ui-kitten/components";

type SelectOption<T> = { label: string; value: T };

export interface SelectProps<T> {
  options: SelectOption<T>[];
  onSelect: (value: T[]) => void;
  value: T | T[];
  summarizeModelName?: string;
  multi?: boolean;
  accessoryLeft?: ReactElement;
}

export function Select<T>({
  options,
  onSelect,
  summarizeModelName = "items",
  value,
  accessoryLeft,
  multi = false,
}: SelectProps<T>) {
  const handlePathSelection = useCallback(() => {
    if (Array.isArray(value) && value.length > 0) {
      return options
        .filter(({ value: v }) => value.map((v) => JSON.stringify(v)).includes(JSON.stringify(v)))
        .map(({ label }) => new IndexPath(options.map(({ label: l }) => l).indexOf(label)));
    }
    return [new IndexPath(0)];
  }, [value]);

  useEffect(() => {
    handlePathSelection();
  }, [value, handlePathSelection]);

  const [selectedPaths, setSelectedPaths] = useState<IndexPath[]>(handlePathSelection());

  const selectedOptions = useDeepCompareMemo(() => {
    const indices = selectedPaths.map(({ row }) => row);
    return options.filter((_v, i) => indices.includes(i));
  }, [options, selectedPaths, value]);

  const handleSelection = (path: IndexPath | IndexPath[]) => {
    const paths = Array.isArray(path) ? path : [path];
    const rows = paths.map(({ row }) => row);

    setSelectedPaths(paths);

    const newValues = options.filter((_v, i) => rows.includes(i)).map(({ value }) => value);

    onSelect(newValues);
  };

  return (
    <KittenSelect
      {...{
        selectedIndex: selectedPaths,
        accessoryLeft,
        onSelect: handleSelection,
        value:
          selectedOptions.length === options.length
            ? `All ${summarizeModelName}`
            : selectedOptions.length > 2
            ? `${selectedOptions.length} ${summarizeModelName}`
            : selectedOptions?.map(({ label }) => label).join(", "),
        multiSelect: multi,
        size: "large",
        width: "100%",
        style: {
          flex: 1,
        },
      }}
    >
      {options.map(({ label }, index: number) => (
        <SelectItem key={`${label}${index}`} title={label}></SelectItem>
      ))}
    </KittenSelect>
  );
}
