import { createContext, useContext, PropsWithChildren } from "react";
import { useAuth } from "./authProvider";

interface ITenantLabelContext {
  labels: Record<
    "batchNumber" | "deliveryNumber" | "deliveryDate",
    string | undefined
  >;
}

export const TenantLabelContext = createContext<ITenantLabelContext>({
  labels: {} as Record<
    "batchNumber" | "deliveryNumber" | "deliveryDate",
    string | undefined
  >,
});

export const TenantLabelProvider = ({ children }: PropsWithChildren) => {
  const { domain } = useAuth();

  const labels: Record<
    "batchNumber" | "deliveryNumber" | "deliveryDate",
    string | undefined
  > =
    domain === "onetuna.seafaresystems.com"
      ? {
          deliveryNumber: "Tag Name",
          batchNumber: "Batch Number",
          deliveryDate: "Tag Date",
        }
      : ({} as Record<
          "batchNumber" | "deliveryNumber" | "deliveryDate",
          string | undefined
        >);

  return (
    <TenantLabelContext.Provider value={{ labels }}>
      {children}
    </TenantLabelContext.Provider>
  );
};

export const useTenantLabels = () => {
  return useContext(TenantLabelContext);
};
