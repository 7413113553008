import { useForm, DeepPartial, FormProvider } from "react-hook-form";
import { Layout } from "@ui-kitten/components";
import { PropsWithChildren } from "react";

interface FormProps<T> {
  defaultValues: DeepPartial<T>;
}

export function Form<T>({
  defaultValues,
  children,
}: PropsWithChildren<FormProps<T>>) {
  const methods = useForm({
    defaultValues,
  });
  const {
    handleSubmit,
    formState: { errors },
  } = methods;
  return (
    <FormProvider {...methods}>
      <Layout
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          marginTop: 36,
        }}
      >
        {children}
      </Layout>
    </FormProvider>
  );
}
