import { useContext, createContext, PropsWithChildren, useState } from "react";
import { onlineManager } from "@tanstack/react-query";
import { useInterval } from "../hooks/useInterval";

export interface INetworkContext {
  isOnline: boolean;
}

const NetworkContext = createContext<INetworkContext>({
  isOnline: false,
});

export const NetworkContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const [isOnline, setIsOnline] = useState<boolean>(false);

  useInterval(() => {
    setIsOnline(onlineManager.isOnline());
  }, 1000);

  return (
    <NetworkContext.Provider value={{ isOnline }}>
      {children}
    </NetworkContext.Provider>
  );
};

export const useNetworkContext = () => useContext(NetworkContext);
