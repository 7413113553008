import { useMemo, Fragment } from "react";
import {
  Layout,
  Button,
  Text,
  Divider,
  StyleService,
  useStyleSheet,
  useTheme,
} from "@ui-kitten/components";
import { StyleProp, Platform } from "react-native";
import moment from "moment";

import { Fisher, RSBaitFisher, RSBaitOrder } from "../api";
import { NavigationProp } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { OrdersStackParamsList } from "../screens";
import { Icon } from "./Icon";
import { useQueryClient } from "@tanstack/react-query";
import {
  faCalendar,
  faCheckCircle,
  faClock,
  faEdit,
  faMapPin,
  faUser,
  faWarning,
} from "@fortawesome/pro-regular-svg-icons";

interface BaitOrderCardProps {
  navigation:
    | NavigationProp<OrdersStackParamsList>
    | NativeStackNavigationProp<OrdersStackParamsList>;
  order: RSBaitOrder;
  style?: StyleProp<{}>;
}

const themedStyles = StyleService.create({
  container: {
    marginVertical: 20,
    marginHorizontal: 40,
    flexDirection: "column",
  },
  header: {
    flexDirection: "row",
    margin: 0,
    marginTop: 12,
    paddingHorizontal: 24,
  },
  headerRow: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 20,
  },
  headerRowLast: {
    flexDirection: "row",
    alignItems: "center",
  },
  headerRowIcon: {
    width: 22,
    height: 22,
    marginRight: 10,
  },
  headerContext: {
    flexDirection: "row",
    paddingHorizontal: 24,
    paddingVertical: 12,
  },

  summary: {
    flexDirection: "column",
    paddingHorizontal: 24,
    paddingBottom: 8,
  },
  footer: {
    padding: 8,
    flexDirection: "row",
    alignItems: "center",
  },
});

const toName = ({
  companyName,
  firstName,
  lastName,
}: Fisher | RSBaitFisher) => {
  if (companyName) {
    return companyName;
  }
  if (firstName && lastName) {
    return `${lastName}, ${firstName}`;
  }
  if (!lastName && firstName) {
    return firstName;
  }
  if (lastName && !firstName) {
    return lastName;
  }
};

export const BaitOrderCard = ({
  order: {
    id,
    fisher,
    items,
    date,
    user,
    locationKey,
    syncedToBackend = true,
    syncedToCounterPoint = false,
    syncedToCounterPointAt,
    syncedToCounterPointTicketId,
    createdAt,
    updatedAt,
  },
  style = {},
  navigation,
}: BaitOrderCardProps) => {
  const styles = useStyleSheet(themedStyles);
  const theme = useTheme();
  const queryClient = useQueryClient();

  if (!fisher) {
    return null;
  }

  const sortedItems = useMemo(
    () =>
      items.sort(({ count: a }, { count: b }) => (a > b ? -1 : a < b ? 1 : 0)),
    [items]
  );

  const syncStatusText = () => {
    if (Platform.OS === "web") {
      return syncedToCounterPoint
        ? `Synced ${
            syncedToCounterPointAt
              ? moment(syncedToCounterPointAt).calendar()
              : ""
          }${
            syncedToCounterPointTicketId
              ? " Ticket #" + syncedToCounterPointTicketId
              : ""
          }`
        : "Not synced to Counter Point";
    }
    return syncedToBackend ? "SAVED TO OFFICE" : "NOT SAVED TO OFFICE";
  };

  return (
    <Layout key={`${id}-${updatedAt}`} style={styles.container}>
      <Layout style={styles.header}>
        <Text category="h6">{toName(fisher)}</Text>
        <Layout style={{ flexGrow: 1 }}></Layout>
        <Text category="s1" style={{ marginLeft: 12 }}>
          {`# ${fisher.counterPointId}`}
        </Text>
      </Layout>
      <Layout style={styles.headerContext}>
        {Platform.OS === "web" && (
          <Layout style={styles.headerRow}>
            <Icon
              icon={faUser}
              color={theme["background-alternative-color-2"]}
              style={{ ...styles.headerRowIcon }}
            />
            <Text category="s1">{user.firstName}</Text>
          </Layout>
        )}
        <Layout style={styles.headerRow}>
          <Icon
            icon={faMapPin}
            color={theme["background-alternative-color-2"]}
            style={{ ...styles.headerRowIcon }}
          />
          <Text category="s1">{locationKey}</Text>
        </Layout>
        <Layout style={styles.headerRow}>
          <Icon
            icon={faCalendar}
            color={theme["background-alternative-color-2"]}
            style={{ ...styles.headerRowIcon }}
          ></Icon>
          <Text category="s1">
            {date ? moment(date).format("DD/MM/YYYY") : ""}
          </Text>
        </Layout>
        <Layout style={styles.headerRowLast}>
          <Icon
            icon={faClock}
            color={theme["background-alternative-color-2"]}
            style={styles.headerRowIcon}
          ></Icon>
          <Text category="s1">{date ? moment(date).format("h:mm A") : ""}</Text>
        </Layout>
        <Layout style={{ flex: 1 }}></Layout>
      </Layout>
      <Divider />
      <Layout style={styles.summary}>
        {sortedItems.map(
          (
            { quantity: { unit, value } = {}, species: { name }, count },
            index
          ) => (
            <Fragment key={`${value}-${unit}-${name}-${index}`}>
              <Layout
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  paddingVertical: 4,
                  flexGrow: 1,
                }}
              >
                <Text category="h6">{count}</Text>
                <Text category="s1" style={{ marginHorizontal: 20 }}>
                  x
                </Text>
                <Text category="h6">{`${value} ${unit}`}</Text>
                <Layout style={{ flex: 1 }} />
                <Text category="h6">{name}</Text>
              </Layout>
              {index !== sortedItems.length - 1 && (
                <Divider key={`${value}-${unit}-${name}-${index}-divider`} />
              )}
            </Fragment>
          )
        )}
      </Layout>
      <Layout style={styles.footer} level="3">
        <Layout level="3">
          <Button
            appearance="ghost"
            status="basic"
            accessoryLeft={(props) => (
              <Icon icon={syncedToBackend ? faCheckCircle : faWarning} />
            )}
          >
            {syncStatusText()}
          </Button>
        </Layout>
        <Layout level="3" style={{ flex: 1 }}></Layout>
        {!syncedToCounterPoint && (
          <Layout>
            <Button
              appearance="ghost"
              status="primary"
              accessoryLeft={(props) => <Icon icon={faEdit} />}
              onPress={() => {
                queryClient.setQueryData(["baitOrders", id], {
                  id,
                  fisher,
                  items,
                  date,
                  user,
                  locationKey,
                  syncedToBackend,
                  syncedToCounterPoint,
                  syncedToCounterPointAt,
                  syncedToCounterPointTicketId,
                  createdAt,
                  updatedAt,
                });
                navigation.navigate("Order", {
                  orderId: id,
                });
              }}
            >
              Edit Order
            </Button>
          </Layout>
        )}
      </Layout>
    </Layout>
  );
};
