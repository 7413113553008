import { createApiProvider, createUseApiHook } from "@tracktile/axiom";

import {
  StagedDelivery,
  Fisher,
  Species,
  User,
  Station,
  Vessel,
  Signature,
  RSBaitOrder,
  RSBaitFisher,
  RSBaitSpecies,
} from "./models";

export type {
  Fisher,
  RSBaitFisher,
  RSBaitOrder,
  RSBaitOrderItem,
  RSBaitQuantity,
  RSBaitSpecies,
  Species,
  SpeciesQuantity,
  StagedDelivery,
  StagedDeliveryItem,
  User,
  Station,
  Vessel,
  Signature,
} from "./models";

export const models = {
  StagedDelivery,
  Fisher,
  Species,
  User,
  Station,
  Vessel,
  Signature,
  RSBaitOrder,
  RSBaitFisher,
  RSBaitSpecies,
};

export const useApi = createUseApiHook<typeof models>();

export const ApiProvider = createApiProvider<typeof models>();
