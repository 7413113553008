import React, { useMemo, useState, FC, PropsWithChildren } from "react";
import {
  Button,
  Layout,
  StyleService,
  useStyleSheet,
} from "@ui-kitten/components";
import RNModal from "react-native-modal";
import { Dimensions, Platform } from "react-native";
import { faCorner } from "@fortawesome/pro-regular-svg-icons";
import { Icon } from "../components";

const themedStyles = StyleService.create({
  container: {
    minHeight: 192,
    width: "100%",
  },
});

export interface UseModalParams {
  title?: string;
}

interface ModalProps {
  title?: string;
  isOpen: boolean;
  show: () => void;
  hide: () => void;
  toggle: () => void;
}

interface UseModalState extends ModalProps {
  modalProps: ModalProps;
}

const DEFAULT_CONFIRMATION_PARAMS = {
  title: "Are You Sure?",
} as UseModalParams;

export const Modal: FC<PropsWithChildren<ModalProps>> = ({
  children,
  title,
  hide,
  isOpen,
}) => {
  const styles = useStyleSheet(themedStyles);
  const { width, height } = Dimensions.get("window");
  const { scaledWidth, scaledHeight } = useMemo(() => {
    const paddingPercentY = 10;
    const paddingPercentX = 10;
    return {
      scaledWidth: width - width * (paddingPercentX / 100) * 1.5,
      scaledHeight: height - height * (paddingPercentY / 100) * 2,
    };
  }, [width, height]);

  return (
    <RNModal
      isVisible={isOpen}
      backdropColor="rgba(0, 0, 0, 0.5)"
      onBackdropPress={hide}
    >
      <Layout
        style={{
          position: "absolute",
          top: -24,
          left: -24,
          zIndex: 12,
          borderRadius: 40,
        }}
      >
        <Button
          style={{ borderRadius: 40, width: 24, height: 24 }}
          size="large"
          appearance="filled"
          status="danger"
          onPress={hide}
          accessoryLeft={(props) => <Icon icon={faCorner} />}
        ></Button>
      </Layout>
      <Layout
        style={{
          paddingHorizontal: 20,
          flexGrow: 1,
          ...(Platform.OS !== "web"
            ? {
                width: scaledWidth,
                height: scaledHeight,
              }
            : {
                maxWidth: scaledWidth,
                maxHeight: scaledHeight,
                minWidth: 920,
              }),
        }}
      >
        {children}
      </Layout>
    </RNModal>
  );
};

export const useModal = (
  params: Partial<UseModalParams> = DEFAULT_CONFIRMATION_PARAMS
): UseModalState => {
  const { title } = {
    ...DEFAULT_CONFIRMATION_PARAMS,
    ...params,
  };
  const [isOpen, setIsOpen] = useState(false);
  const show = () => setIsOpen(true);
  const hide = () => setIsOpen(false);
  const toggle = () => setIsOpen(!isOpen);

  const modalProps: ModalProps = {
    show,
    hide,
    toggle,
    title,
    isOpen,
  };

  return { modalProps, ...modalProps };
};
