import moment from "moment";
import { v4 as uuid } from "uuid";
import { useState } from "react";
import { useNavigation } from "@react-navigation/native";
import { useBaitLocation } from "../baitLocationProvider";
import { useUser } from "../authProvider";
import {
  RSBaitFisher,
  RSBaitOrder,
  RSBaitOrderItem,
  RSBaitQuantity,
  RSBaitSpecies,
  useApi,
} from "../../api";

const sortByPosition = (
  { position: a }: RSBaitOrderItem,
  { position: b }: RSBaitOrderItem
) => (a > b ? 1 : a < b ? -1 : 0);

export const useBaitOrder = (orderId?: string) => {
  const api = useApi();
  const navigation = useNavigation();
  const { location } = useBaitLocation();
  const user = useUser();

  const [hasOrderChanged, setHasOrderChanged] = useState<boolean>(false);

  let existingOrderData = api.RSBaitOrder.read(orderId ?? "");
  // TODO: Jarred, this is failing because we are no longer pulling form the right query key. We need to update this to pull from the correct key in the cache based on the selected data range on the BaitOrdersScreen.

  if (!!existingOrderData) {
    existingOrderData = {
      ...existingOrderData,
      isNew: false,
    };
  }

  const [baitOrder, setBaitOrderState] = useState<RSBaitOrder>({
    id: uuid(),
    isNew: true,
    date: moment().valueOf(),
    items: [],
    user,
    locationKey: location.key,
    createdAt: Date.now(),
    updatedAt: Date.now(),
    syncedToBackend: false,
    syncedToCounterPoint: false,
    ...existingOrderData,
  });

  const setBaitOrder = (stateFn: (state: RSBaitOrder) => RSBaitOrder) => {
    setBaitOrderState(stateFn(baitOrder));
    setHasOrderChanged(true);
  };

  const { date, items: baitItems, fisher, locationKey } = baitOrder;

  const setDate = (date: Date) => {
    setBaitOrder((order) => ({ ...order, date: moment(date).valueOf() }));
  };

  const setLocationKey = (locationKey: string) => {
    setBaitOrder((order) => ({ ...order, locationKey }));
  };

  const setFisher = (fisher: RSBaitFisher) => {
    setBaitOrder((order) => ({ ...order, fisher }));
  };

  const setBaitItems = (items: RSBaitOrderItem[]) =>
    setBaitOrder((order) => ({ ...order, items: items.sort(sortByPosition) }));

  const setSpeciesForItem = (
    species: Pick<RSBaitSpecies, "id" | "name">,
    itemId: string
  ) => {
    const item = baitItems.find(({ id }) => id === itemId);
    if (!item) return;
    setBaitItems([
      ...baitItems.filter(({ id }) => id !== itemId),
      { ...item, species },
    ]);
  };

  const { mutate: createOrderMutation } = api.RSBaitOrder.create();
  const { mutate: updateOrderMutation } = api.RSBaitOrder.update();
  const { mutate: deleteOrderMutation } = api.RSBaitOrder.remove();

  const setQuantityForItem = (
    { value, unit, counterPointId, price }: RSBaitQuantity,
    itemId: string
  ) => {
    const item = baitItems.find(({ id }) => id === itemId);

    if (!item) return;

    setBaitItems([
      ...baitItems.filter(({ id }) => id !== itemId),
      {
        ...item,
        quantity: { value, unit, counterPointId, enabled: true, price },
      },
    ]);
  };

  const setCountForItem = (count: number, itemId: string) => {
    const item = baitItems.find(({ id }) => id === itemId);

    if (!item) return;

    setBaitItems([
      ...baitItems.filter(({ id }) => id !== itemId),
      { ...item, count },
    ]);
  };

  const addItem = (species: RSBaitSpecies, quantity?: RSBaitQuantity) => {
    setBaitItems([
      ...baitItems,
      {
        id: uuid(),
        species: {
          id: species.id,
          name: species.name,
        },
        position: baitItems.length,
        quantity: quantity ?? {
          unit: "",
          value: 0,
          counterPointId: "",
          enabled: true,
          price: 0,
        },
        count: 1,
      },
    ]);
  };

  const removeItem = (itemId: string) => {
    setBaitItems([...baitItems.filter(({ id }) => id !== itemId)]);
  };

  const resetOrder = () => {
    setBaitOrder(() => ({
      id: uuid(),
      isNew: true,
      date: moment().valueOf(),
      items: [],
      locationKey: location.key,
      user,
      createdAt: Date.now(),
      updatedAt: Date.now(),
      syncedToCounterPoint: false,
      syncedToBackend: false,
    }));
  };

  const submitOrder = async () => {
    if (baitOrder.isNew) {
      createOrderMutation({ ...baitOrder, id: uuid() });
      resetOrder();
    } else {
      updateOrderMutation(baitOrder);
      navigation.navigate("Orders");
    }
  };

  const deleteOrder = async () => {
    await deleteOrderMutation(baitOrder);
    resetOrder();
  };

  const canCheckout =
    (orderId
      ? hasOrderChanged &&
        baitItems.length > 0 &&
        !!fisher &&
        baitItems.every(({ quantity: { value, unit } }) => !!value && !!unit)
      : baitItems.length > 0 &&
        !!fisher &&
        baitItems.every(
          ({ quantity: { value, unit } }) => !!value && !!unit
        )) && !!locationKey;

  console.log({ locationKey, canCheckout });

  const canCancelOrder = orderId
    ? hasOrderChanged
    : baitItems.length > 0 || !!fisher;

  console.log({ fisher });

  return {
    date,
    fisher,
    locationKey,
    setDate,
    setFisher,
    items: baitItems,
    setSpeciesForItem,
    setQuantityForItem,
    setCountForItem,
    setLocationKey,
    addItem,
    removeItem,
    resetOrder,
    submitOrder,
    deleteOrder,
    canCheckout,
    canCancelOrder,
  };
};
