import { FC, useState, useMemo } from "react";
import { FlatList } from "react-native";
import { FlashList } from "@shopify/flash-list";
import { useFormContext } from "react-hook-form";
import FuzzySearch from "fuzzy-search";
import {
  Layout,
  TabBar,
  Tab,
  Text,
  Input,
  Divider,
  Toggle,
  CheckBox,
  Button,
  Spinner,
  useTheme,
} from "@ui-kitten/components";

import { useApi, User, RSBaitFisher, RSBaitSpecies } from "../api";
import { RootScreenProps } from "../nav";
import { useModal, Modal } from "../hooks/useModal";
import { useConfirmation } from "../hooks/useConfirmation";
import { useAuth } from "../context/authProvider";
import {
  TopNavigation,
  CenteredContainer,
  ControlledInput,
  ControlledSubmitButton,
  Form,
  Icon,
} from "../components";
import {
  faBackward,
  faFish,
  faHashtag,
  faOption,
  faShip,
  faTags,
  faTrash,
  faUserPlus,
  faUsers,
} from "@fortawesome/pro-regular-svg-icons";

interface AdminFisherRowProps {
  fisher: RSBaitFisher;
}

const AdminFisherRow = ({ fisher }: AdminFisherRowProps) => {
  const api = useApi();
  const theme = useTheme();
  const { mutate: putFisherState, isPending: isLoading } =
    api.RSBaitFisher.update();

  return (
    <Layout
      style={{
        flex: 1,
        marginVertical: 9,
      }}
      level="4"
    >
      <Layout
        style={{
          flex: 1,
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          paddingVertical: 6,
        }}
        level="4"
      >
        <Layout
          level="1"
          style={{
            paddingVertical: 20,
            paddingHorizontal: 10,
            borderRadius: 6,
            flexGrow: 1,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Layout
            style={{ flexDirection: "row", flexGrow: 1, alignItems: "center" }}
          >
            <Text category="s1">
              {fisher.firstName} {fisher.lastName}
            </Text>
            <Layout style={{ flex: 1 }} />
            {fisher.companyName && (
              <Layout
                style={{
                  marginRight: 18,
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Icon
                  icon={faTags}
                  color={"rba(34, 43, 69)"}
                  size={18}
                  style={{
                    marginRight: 3,
                  }}
                />
                <Text style={{ marginRight: 18 }}>{fisher.companyName}</Text>
              </Layout>
            )}
            <Layout style={{ flexDirection: "row", alignItems: "center" }}>
              <Icon
                icon={faHashtag}
                color={theme["color-control-default"]}
                size={18}
                style={{
                  marginRight: 3,
                }}
              />
              <Text>{fisher.counterPointId}</Text>
            </Layout>
          </Layout>
          <Layout style={{ marginLeft: 20 }}>
            {isLoading && <Spinner />}
            {!isLoading && (
              <Toggle
                checked={fisher.enabled}
                onChange={(enabled: boolean) => {
                  putFisherState({ ...fisher, enabled });
                }}
              ></Toggle>
            )}
          </Layout>
        </Layout>
      </Layout>
    </Layout>
  );
};

const AdminFishers = () => {
  const api = useApi();
  const { data: fishers = [] } = api.RSBaitFisher.all();

  const [searchString, setSearchString] = useState<string>();

  const filteredFishers = useMemo(() => {
    return new FuzzySearch(
      fishers,
      ["firstName", "lastName", "email", "companyName", "counterPointId"],
      {
        caseSensitive: false,
      }
    )
      .search(searchString)
      .sort(({ lastName: a }, { lastName: b }) => (a < b ? -1 : a > b ? 1 : 0))
      .sort(({ enabled: a }, { enabled: b }) =>
        a && !b ? -1 : !a && b ? 1 : 0
      );
  }, [fishers, searchString]);

  return (
    <Layout style={{ flex: 1 }} level="4">
      <Input
        size="large"
        placeholder="Search..."
        value={searchString}
        onChangeText={setSearchString}
        style={{ marginVertical: 20 }}
      ></Input>
      <FlashList
        style={{
          flexGrow: 1,
          flexDirection: "column",
          paddingRight: 18,
          paddingBottom: 120,
        }}
        keyExtractor={(fisher: RSBaitFisher) => `${fisher.counterPointId}`}
        data={filteredFishers}
        renderItem={({ item: fisher }) => <AdminFisherRow fisher={fisher} />}
      ></FlashList>
    </Layout>
  );
};

interface AdminInventoryRowProps {
  species: RSBaitSpecies;
}

const AdminInventoryRow = ({ species }: AdminInventoryRowProps) => {
  const api = useApi();
  const theme = useTheme();

  const { mutate: putSpeciesState, isPending: isLoading } =
    api.RSBaitSpecies.update();

  return (
    <Layout
      style={{
        flex: 1,
        marginVertical: 9,
        borderRadius: 6,
        overflow: "hidden",
      }}
      level="4"
    >
      <Layout
        style={{
          flex: 1,
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        level="4"
      >
        <Layout
          level="1"
          style={{
            borderRadius: 6,
            flexGrow: 1,
          }}
        >
          <Layout
            style={{
              flexDirection: "row",
              flexGrow: 1,
              alignItems: "center",
              justifyContent: "space-between",
              padding: 18,
            }}
          >
            <Text category="s1">{species.name}</Text>
            <Layout style={{ flexGrow: 1 }}></Layout>
            <Toggle
              checked={species.enabled}
              onChange={(enabled: boolean) => {
                putSpeciesState({ ...species, enabled });
              }}
            ></Toggle>
          </Layout>
          <Divider />
          <Layout
            style={{
              display: "flex",
              flexGrow: 1,
              flexDirection: "column",
            }}
          >
            {species.quantities
              .sort(({ value: a }, { value: b }) =>
                a > b ? 1 : a < b ? -1 : 0
              )
              .map((quantity) => (
                <>
                  <Layout
                    style={{
                      flexDirection: "row",
                      flexGrow: 1,
                      alignItems: "center",
                      padding: 18,
                    }}
                  >
                    <Text
                      category="s1"
                      style={{ marginRight: 18, minWidth: 80 }}
                    >
                      {quantity.value} {quantity.unit}
                    </Text>

                    <Layout
                      style={{ flexDirection: "row", alignItems: "center" }}
                    >
                      <Icon
                        icon={faHashtag}
                        color={theme["color-control-default"]}
                        size={18}
                        style={{
                          marginRight: 3,
                        }}
                      />
                      <Text>{quantity.counterPointId}</Text>
                    </Layout>

                    <Layout style={{ flexGrow: 1 }} />
                    {isLoading && <Spinner />}
                    {!isLoading && (
                      <CheckBox
                        checked={quantity.enabled}
                        disabled={!species.enabled}
                        onChange={(enabled: boolean) => {
                          putSpeciesState({
                            ...species,
                            quantities: species.quantities.map((q) => {
                              if (
                                q.value === quantity.value &&
                                q.unit === q.unit
                              ) {
                                return { ...q, enabled };
                              }
                              return q;
                            }),
                          });
                        }}
                      />
                    )}
                  </Layout>
                  <Divider />
                </>
              ))}
          </Layout>
        </Layout>
      </Layout>
      <Divider />
    </Layout>
  );
};

const AdminInventory = () => {
  const api = useApi();
  const { data: species = [] } = api.RSBaitSpecies.all();
  const [searchString, setSearchString] = useState<string>();

  const filteredSpecies = useMemo(() => {
    return new FuzzySearch(
      species.map((species) => ({
        ...species,
        searchKey: species.quantities
          .map(({ counterPointId }) => counterPointId)
          .join("-"),
      })),
      ["name", "searchKey"],
      {
        caseSensitive: false,
      }
    )
      .search(searchString)
      .sort(({ name: a }, { name: b }) => (a < b ? -1 : a > b ? 1 : 0));
  }, [species, searchString]);

  return (
    <Layout style={{ flex: 1 }} level="4">
      <Input
        size="large"
        placeholder="Search..."
        value={searchString}
        onChangeText={setSearchString}
        style={{ marginVertical: 20 }}
      ></Input>
      <FlashList
        style={{
          flexGrow: 1,
          flexDirection: "column",
          paddingRight: 18,
          paddingBottom: 120,
        }}
        keyExtractor={(species: RSBaitSpecies) => `${species.id}`}
        data={filteredSpecies}
        renderItem={({ item: species }) => (
          <AdminInventoryRow species={species} />
        )}
      ></FlashList>
    </Layout>
  );
};

interface AdminUserRowProps {
  user: User;
  onEdit: () => void;
}

const AdminUserRow = ({ user, onEdit }: AdminUserRowProps) => {
  const api = useApi();
  const theme = useTheme();

  const { mutate: putUserState } = api.User.update();
  const { mutateAsync: deleteUser } = api.User.remove();

  const {
    Modal: DeleteUserConfirmationModal,
    show: showDeleteUserConfirmation,
  } = useConfirmation({
    title: `Delete ${user.firstName} ${user.lastName}`,
    description:
      "Once deleted this user will no longer be able to log into the bait app.",
    cancelText: "Nevermind",
    confirmText: "Proceed",
    onConfirm: async () => {
      await deleteUser(user);
      api.User.invalidateAll();
    },
  });

  const { toggle: toggleEditUserModal, modalProps: editUserModalProps } =
    useModal({
      title: `Edit ${user.firstName} ${user.lastName}`,
    });

  return (
    <>
      <Layout
        style={{
          flex: 1,
          marginVertical: 6,
        }}
        level="4"
      >
        <Layout
          style={{
            padding: 12,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Text category="s1">{`${user.firstName} ${user.lastName}`}</Text>
          <Layout style={{ flexGrow: 1 }}></Layout>
          <Button
            appearance="outline"
            status="basic"
            onPress={onEdit}
            accessoryLeft={(props) => (
              <Icon
                icon={faOption}
                color={theme["background-alternative-color-2"]}
              />
            )}
          ></Button>
          <Button
            appearance="ghost"
            onPress={() => showDeleteUserConfirmation()}
            accessoryLeft={(props) => (
              <Icon
                icon={faTrash}
                color={theme["background-alternative-color-2"]}
              />
            )}
          ></Button>
        </Layout>
      </Layout>
      <DeleteUserConfirmationModal />

      <Modal {...editUserModalProps} />
    </>
  );
};

const UserEditForm = ({
  title,
  onClose,
  onSubmit,
  isSubmitting,
  proceedButtonText,
  requirePassword = false,
  disableEmail = false,
}: {
  title: string;
  onClose: () => void;
  onSubmit: (user: User) => void;
  isSubmitting: boolean;
  proceedButtonText: string;
  requirePassword?: boolean;
  disableEmail?: boolean;
}) => {
  const theme = useTheme();
  const { watch } = useFormContext();
  return (
    <Layout style={{ flexDirection: "column", flexGrow: 1 }}>
      <Text category="h2">{title}</Text>
      <Layout style={{ flexDirection: "row", marginVertical: 18, flexGrow: 1 }}>
        <Layout style={{ flexDirection: "column", flexGrow: 1 }}>
          <Layout style={{ flexDirection: "row", flexGrow: 1 }}>
            <ControlledInput
              name="firstName"
              label="First Name"
              rules={{
                required: "First name is required.",
                minLength: {
                  value: 2,
                  message: "Must be at least 3 characters.",
                },
                maxLength: { value: 120, message: "Too long!" },
              }}
            />

            <Layout style={{ flexGrow: 0, minWidth: 18 }}></Layout>
            <ControlledInput
              name="lastName"
              label="Last Name"
              rules={{
                required: "Last name is required.",
                minLength: {
                  value: 2,
                  message: "Must be at least 3 characters.",
                },
                maxLength: { value: 120, message: "Too long!" },
              }}
            />
          </Layout>
          <ControlledInput
            name="email"
            disabled={disableEmail}
            label="Email Address"
            rules={{
              required: "Email Address is required.",
              minLength: {
                value: 2,
                message: "Must be at least 3 characters.",
              },
              maxLength: { value: 999, message: "Too long!" },
            }}
          />
          <Layout style={{ flexDirection: "row" }}>
            <ControlledInput
              name="password"
              label="Password"
              rules={{
                required: requirePassword ? "Password is required." : false,
                minLength: {
                  value: 6,
                  message: "Password must be at least 6 characters.",
                },
                maxLength: { value: 256, message: "Password is too long." },
              }}
              secureTextEntry
            />
            <Layout style={{ flexGrow: 0, minWidth: 18 }}></Layout>
            <ControlledInput
              name="confirmPassword"
              label="Confirm Password"
              rules={{
                required:
                  requirePassword || watch("password")?.length === 0
                    ? "Confirm Password is required."
                    : false,
                validate: (value: string) => {
                  const watchedPassword = watch("password");
                  if (watchedPassword !== value) {
                    return "Passwords do not match.";
                  }
                  return true;
                },
              }}
              secureTextEntry
            />
          </Layout>
        </Layout>
      </Layout>
      <Layout
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginVertical: 18,
        }}
      >
        <Button
          status="danger"
          disabled={isSubmitting}
          accessoryLeft={(props) => (
            <Icon icon={faBackward} color={theme["color-control-default"]} />
          )}
          onPress={() => onClose()}
        >
          Cancel
        </Button>
        <Layout style={{ flexGrow: 0, width: 18 }} />
        <ControlledSubmitButton
          disabled={isSubmitting}
          onSubmit={() => onSubmit(watch() as User)}
          status="primary"
          accessoryLeft={(props) =>
            isSubmitting ? (
              <Spinner />
            ) : (
              <Icon icon={faUserPlus} color={theme["color-control-default"]} />
            )
          }
        >
          {proceedButtonText}
        </ControlledSubmitButton>
      </Layout>
    </Layout>
  );
};

export const AdminUsers = () => {
  const api = useApi();

  const { toggle: toggleNewUserModal, modalProps: newUserModalProps } =
    useModal({ title: "New User" });

  const { toggle: toggleEditUserModal, modalProps: editUserModalProps } =
    useModal({ title: "Edit User" });

  const { data: users = [] } = api.User.all();
  const { mutate: postUser, isPending: isPostingUser } = api.User.create({
    onSuccess: () => {
      toggleNewUserModal();
    },
  });

  const { mutate: putUser, isPending: isPuttingUser } = api.User.update({
    onSuccess: () => {
      toggleEditUserModal();
    },
  });

  const [editUserModalTarget, setEditUserModalTarget] = useState<Partial<User>>(
    {}
  );

  const [searchString, setSearchString] = useState<string>();

  const filteredUsers = useMemo(() => {
    return new FuzzySearch(
      users,
      ["firstName", "lastName", "email", "counterPointId"],
      {
        caseSensitive: false,
      }
    )
      .search(searchString)
      .sort(({ lastName: a }, { lastName: b }) => (a < b ? -1 : a > b ? 1 : 0));
  }, [users, searchString]);

  return (
    <>
      <Layout style={{ flex: 1 }} level="4">
        <Layout
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginVertical: 20,
          }}
          level="4"
        >
          <Input
            size="large"
            placeholder="Search..."
            value={searchString}
            onChangeText={setSearchString}
            style={{ flexGrow: 1 }}
          ></Input>
          <Button
            onPress={() => toggleNewUserModal()}
            accessoryLeft={(props) => <Icon icon={faUserPlus} />}
            style={{ marginLeft: 20 }}
          >
            New User
          </Button>
        </Layout>
        <FlashList
          style={{
            flexGrow: 1,
            flexDirection: "column",
            paddingRight: 0,
            paddingBottom: 120,
          }}
          keyExtractor={(user: User) => `${user.appUserId}`}
          data={filteredUsers}
          renderItem={({ item: user }) => (
            <AdminUserRow
              user={user}
              onEdit={() => {
                setEditUserModalTarget(user);
                toggleEditUserModal();
              }}
            />
          )}
        ></FlashList>
      </Layout>
      <Modal {...newUserModalProps}>
        <Form defaultValues={{}}>
          <UserEditForm
            title="New User"
            proceedButtonText="Create User"
            onClose={() => toggleNewUserModal()}
            onSubmit={postUser}
            isSubmitting={isPostingUser}
            requirePassword
          />
        </Form>
      </Modal>
      <Modal {...editUserModalProps}>
        <Form key={editUserModalTarget.id} defaultValues={editUserModalTarget}>
          <UserEditForm
            title="Edit User"
            proceedButtonText="Save User"
            onClose={() => toggleEditUserModal()}
            onSubmit={putUser}
            isSubmitting={isPuttingUser}
            disableEmail
          />
        </Form>
      </Modal>
    </>
  );
};

export const AdminScreen: FC<RootScreenProps> = ({
  navigation,
}: RootScreenProps) => {
  const { tenant } = useAuth();
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

  const tabs = useMemo(
    () =>
      [
        {
          title: "Fishers",
          icon: faShip,
          Component: AdminFishers,
          enabled: !!tenant?.baitOrders?.enabled,
        },
        {
          title: "Inventory",
          icon: faFish,
          Component: AdminInventory,
          enabled: !!tenant?.baitOrders?.enabled,
        },
        {
          title: "App Users",
          icon: faUsers,
          Component: AdminUsers,
          enabled: true,
        },
      ].filter(({ enabled }) => !!enabled),
    [tenant]
  );

  const renderTab = () => {
    const { Component } = tabs[selectedTabIndex];
    return <Component />;
  };

  return (
    <Layout level="4" style={{ flex: 1 }}>
      <TopNavigation navigation={navigation} title="Admin" showMenuAction />
      <CenteredContainer level="4">
        <TabBar
          style={{ marginTop: 40, backgroundColor: "rgba(0,0,0,0)" }}
          selectedIndex={selectedTabIndex}
          onSelect={(index: number) => setSelectedTabIndex(index)}
        >
          {tabs.map(({ title, icon, Component }) => (
            <Tab
              key={title}
              title={title}
              icon={(props) => <Icon icon={icon} />}
            ></Tab>
          ))}
        </TabBar>
        <Layout style={{ flex: 1, flexDirection: "column" }} level="4">
          {renderTab()}
        </Layout>
      </CenteredContainer>
    </Layout>
  );
};
