import { ReactNode } from "react";
import { TouchableHighlight as RNTouchableHighlight } from "react-native-gesture-handler";
import { StyleProp, ViewStyle } from "react-native";

interface TouchableHighlightProps {
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
  children: ReactNode;
}

export const TouchableHighlight = ({ onPress = () => {}, style = {}, children }: TouchableHighlightProps) => (
  <RNTouchableHighlight
    {...{
      onPress,
      underlayColor: "rgba(0, 0, 0, 0.1)",
      activeOpacity: 0.8,
      style,
    }}
  >
    {children}
  </RNTouchableHighlight>
);
