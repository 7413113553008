import { request } from "@tracktile/axiom";

import { getConfig } from "../config";
import { useStorage } from "../lib/storage";

const { api } = getConfig();

export const syncBaitOrderIdToCounterPoint = async (id: string) => {
  const [token] = useStorage<string>("token");
  return request(`${api}/royalstar/bait/order/${id}/send`, {
    method: "POST",
    token,
  });
};
