import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Layout,
  Text,
  Card,
  Button,
  RangeDatepicker,
  Datepicker,
  CalendarRange,
  Input,
  useTheme,
} from "@ui-kitten/components";
import moment from "moment";
import { FlatList, Pressable, Platform } from "react-native";
import { FlashList } from "@shopify/flash-list";
import { useFocusEffect } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";

import {
  TopNavigation,
  Select,
  FisherSelectionModal,
  CenteredContainer,
  LandingCard,
  Icon,
} from "../../components";
import { useModal, useConfirmation, Modal } from "../../hooks";
import { useApi, Fisher } from "../../api";
import { useAuth } from "../../context/authProvider";

import {
  DateRangeSelectionOption,
  DateRangeLabelMap,
  dateRangeSelectionMap,
} from "../../lib/date-range";

import { LandingsStackParamsList } from "./LandingsNavigator";
import { useStations } from "../../context/stationsProvider";
import { useLanding } from "../../context/landingProvider";
import { SafeAreaView } from "react-native-safe-area-context";
import { faGlobe, faSync } from "@fortawesome/pro-regular-svg-icons";

export const LandingsScreen = ({
  navigation,
}: NativeStackScreenProps<LandingsStackParamsList>) => {
  const api = useApi();

  const refreshIconRef = useRef<typeof Icon>(null);

  const [dateSelectionMode, setDateSelectionMode] =
    useState<DateRangeSelectionOption>("today");

  const { data: users = [] } = api.User.all();
  const { data: fishers = [] } = api.Fisher.all();
  const {
    data: landings = [],
    isFetching: isFetchingLandings,
    refetch: refetchLandings,
  } = api.StagedDelivery.all();
  const [selectedDeliveryIds, setSelectedDeliveryIds] = useState<String[]>([]);
  // const [unsyncedDeliveryIds, setUnsyncedDeliveryIds] = useState<String[]>([]);

  const [fisherSelection, setFisherSelection] = useState<Fisher[]>([]);

  const [dateRange, setDateRange] = useState<CalendarRange<Date>>({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [isCheckboxVisible, setIsCheckboxVisible] = useState(false);

  const [userFilterSelections, setUserFilterSelections] = useState<string[]>(
    users.map(({ email }) => email)
  );

  const [search, setSearch] = useState<string>("");
  const theme = useTheme();

  const { syncMultipleLandingsToSBM } = useLanding();

  // useEffect(() => {
  //   const animate: () => unknown = () =>
  //     refreshIconRef?.current?.startAnimation(() => animate());
  //   animate();
  // }, [isFetchingLandings]);

  useFocusEffect(
    React.useCallback(() => {
      refetchLandings();
    }, [])
  );

  const filteredLandings = useMemo(() => {
    return landings
      .filter(
        ({
          createdAt,
          fishers,
          items,
          batchNumber,
          slipNumber,
          buyingStation,
          user,
          vessel,
        }) => {
          // combine all the fields into a single variable to implement a "full-text" search. TODO: refactor to a function
          const fishersData: string = `${fishers
            .map((f) => `${f.firstName} ${f.lastName}`)
            .join(" ")}`;
          const speciesData: string = `${items
            .map((i) => i.species.name)
            .join(" ")}`;
          const itemData: string =
            `${fishersData} ${speciesData} ${batchNumber} ${slipNumber} ${buyingStation?.name} ${vessel?.name} ${user.firstName}`.toUpperCase();
          const searchData: string = search.toUpperCase();

          return (
            itemData.indexOf(searchData) > -1 &&
            moment(createdAt).isBetween(
              dateRange.startDate,
              dateRange.endDate,
              "day",
              "[]"
            )
          );
        }
      )
      .sort(({ createdAt: a }, { createdAt: b }) =>
        a < b ? 1 : a > b ? -1 : 0
      );
  }, [landings, dateRange.startDate, dateRange.endDate, search]);

  const unsyncedDeliveryIds = useMemo(
    () =>
      filteredLandings
        .filter(({ syncedToSBM }) => !syncedToSBM)
        .map(({ id }) => id),
    [filteredLandings]
  );

  const handleDateSelectionModeChanged = ([
    mode,
  ]: DateRangeSelectionOption[]) => {
    const value = dateRangeSelectionMap()[mode];

    setDateRange({
      startDate: value.startDate.toDate(),
      endDate: value.endDate.toDate(),
    });

    if (mode === "custom_day") {
      // singleDatePickerRef.current?.show();
      singleDatePickerRef.current?.focus();
    }
    if (mode === "custom_range") {
      // dateRangePickerRef.current?.show();
      dateRangePickerRef.current?.focus();
    }

    setDateSelectionMode(mode);
  };

  const singleDatePickerRef = useRef<Datepicker<Date>>(null);
  const dateRangePickerRef = useRef<RangeDatepicker<Date>>(null);

  const { currentStation } = useStations();
  const screenTitle =
    Platform.OS === "web"
      ? "All Deliveries"
      : `Deliveries ${currentStation ? `(${currentStation.name})` : ""}`;

  const { Modal: SyncMultipleConfirmationModal, show: showSyncMultipleModal } =
    useConfirmation({
      title: `Sync ${selectedDeliveryIds.length} deliveries to fisher accounts?`,
      description: `This will upload the details to fisher accounts. Once synchronized, you cannot edit or delete these deliveries. Are you sure?`,
      cancelText: "Go Back",
      confirmText: `Sync ${selectedDeliveryIds.length} Deliveries to fisher accounts`,
      onConfirm: () => {
        setIsCheckboxVisible(false);
        syncMultipleLandingsToSBM(selectedDeliveryIds);
        setSelectedDeliveryIds([]);
      },
    });

  const { Modal: SyncAllConfirmationModal, show: showSyncAllModal } =
    useConfirmation({
      title: `Sync All Deliveries to Fisher Accounts?`,
      description: `This will upload the details to the fisher accounts. Once synchronized, you cannot edit or delete these deliveries. Are you sure?`,
      cancelText: "Go Back",
      confirmText: `Sync ${unsyncedDeliveryIds.length} Deliveries to fisher accounts`,
      onConfirm: () => {
        setIsCheckboxVisible(false);
        syncMultipleLandingsToSBM(unsyncedDeliveryIds);
      },
    });

  return (
    <Layout level="4" style={{ flex: 1 }}>
      <TopNavigation
        navigation={navigation}
        title={screenTitle}
        showMenuAction
      />
      <Card
        style={{
          paddingHorizontal: 0,
        }}
      >
        <CenteredContainer>
          <Layout
            style={{
              padding: 0,
              flexDirection: "row",
            }}
          >
            <Layout style={{ flexDirection: "row", flex: 1 }}>
              <Layout style={{ flex: 1, maxWidth: 200, flexDirection: "row" }}>
                <Select<DateRangeSelectionOption>
                  // accessoryLeft={<Icon name={"calendar-outline"} />} // TODO: fix?
                  options={Object.entries(DateRangeLabelMap).map(
                    ([value, label]) => ({
                      label,
                      value: value as DateRangeSelectionOption,
                    })
                  )}
                  onSelect={handleDateSelectionModeChanged}
                  value={dateSelectionMode}
                />
              </Layout>
              <Button
                style={{ marginLeft: 10 }}
                appearance={isCheckboxVisible ? "filled" : "outline"}
                onPress={() => {
                  setIsCheckboxVisible(!isCheckboxVisible);
                }}
                accessoryLeft={(props) => <Icon icon={faGlobe} />}
              >
                {Platform.OS === "web" ? "SYNC MULTIPLE TO SBM" : "SYNC"}
              </Button>
            </Layout>
          </Layout>
        </CenteredContainer>
      </Card>
      {(dateSelectionMode === "custom_day" ||
        dateSelectionMode === "custom_range") && (
        <Card
          style={{
            paddingHorizontal: 0,
          }}
        >
          <CenteredContainer>
            <Layout
              style={{
                padding: 0,
                flexDirection: "row",
              }}
            >
              <Layout
                style={{ flexDirection: "row", flex: 1, flexWrap: "wrap" }}
              >
                {dateSelectionMode === "custom_day" && (
                  <Datepicker
                    ref={singleDatePickerRef}
                    status="primary"
                    size="large"
                    date={dateRange.startDate}
                    onSelect={(date) => {
                      setDateRange({
                        startDate: date,
                        endDate: date,
                      });
                    }}
                  />
                )}
                {dateSelectionMode === "custom_range" && (
                  <RangeDatepicker
                    ref={dateRangePickerRef}
                    range={dateRange}
                    status="primary"
                    size="large"
                    boundingMonth={false}
                    onSelect={(nextRange) => {
                      setDateRange(nextRange);
                    }}
                  />
                )}
              </Layout>
            </Layout>
          </CenteredContainer>
        </Card>
      )}
      {isCheckboxVisible && (
        <Card
          style={{
            paddingHorizontal: 0,
          }}
        >
          <CenteredContainer>
            <Layout
              style={{
                padding: 0,
                flexDirection: "row",
              }}
            >
              <Layout
                style={{ flexDirection: "row", flex: 1, flexWrap: "wrap" }}
              >
                <Layout style={{ flex: 1, flexDirection: "row" }}>
                  <Button
                    style={{ marginLeft: 10 }}
                    appearance="outline"
                    onPress={() => {
                      showSyncMultipleModal();
                    }}
                    disabled={selectedDeliveryIds.length <= 0}
                  >
                    {`SYNC SELECTED (${selectedDeliveryIds.length})`}
                  </Button>
                  <Button
                    style={{ marginLeft: 10 }}
                    appearance="outline"
                    status="warning"
                    onPress={() => {
                      showSyncAllModal();
                    }}
                    disabled={unsyncedDeliveryIds.length <= 0}
                  >
                    {`SYNC ALL (${unsyncedDeliveryIds.length})`}
                  </Button>
                </Layout>
              </Layout>
            </Layout>
          </CenteredContainer>
        </Card>
      )}
      {Platform.OS === "web" && (
        <Card
          style={{
            paddingHorizontal: 0,
          }}
        >
          <CenteredContainer>
            <Layout
              style={{
                padding: 0,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Layout
                style={{ flexGrow: 1, paddingRight: 10, paddingLeft: 10 }}
              >
                <Input
                  placeholder="Search"
                  value={search}
                  onChangeText={setSearch}
                />
              </Layout>
              <Layout style={{ flexGrow: 1 }}>
                <Select
                  options={users.map(({ firstName, lastName, email }) => ({
                    label: `${firstName} ${lastName}`,
                    value: email,
                  }))}
                  summarizeModelName="Users"
                  value={userFilterSelections}
                  onSelect={(e) => {
                    setUserFilterSelections(e);
                  }}
                  multi
                />
              </Layout>
            </Layout>
          </CenteredContainer>
        </Card>
      )}
      <Layout
        level="4"
        style={{
          flex: 1,
          justifyContent: "flex-start",
          paddingHorizontal: 0,
        }}
      >
        <CenteredContainer level="4">
          <Layout level="4" style={{ flex: 1 }}>
            {filteredLandings.length === 0 && (
              <Layout
                level="4"
                style={{
                  display: "flex",
                  flex: 100,
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Pressable
                  onPress={() => refetchLandings()}
                  style={{
                    display: "flex",
                    flex: 100,
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Icon
                    style={{ width: 48, height: 48, marginBottom: 12 }}
                    color={theme["background-alternative-color-2"]}
                    icon={faSync}
                  />
                  <Text category="C1">No Landings To Display</Text>
                </Pressable>
              </Layout>
            )}
            <FlashList
              onRefresh={() => refetchLandings()}
              refreshing={isFetchingLandings}
              data={filteredLandings}
              keyExtractor={(item) => `${item.id}-${item.updatedAt}`}
              renderItem={({ item }) => (
                <LandingCard
                  navigation={navigation}
                  delivery={item}
                  isCheckboxVisible={
                    item.syncedToSBM ? false : isCheckboxVisible
                  }
                  isCheckboxSelected={selectedDeliveryIds.includes(item.id)}
                  onCheckboxChange={(
                    isSelected: Boolean,
                    deliveryId: String
                  ) => {
                    if (isSelected) {
                      setSelectedDeliveryIds([
                        ...selectedDeliveryIds,
                        deliveryId,
                      ]);
                    } else {
                      setSelectedDeliveryIds(
                        selectedDeliveryIds.filter(
                          (selectedDeliveryId) =>
                            selectedDeliveryId !== deliveryId
                        )
                      );
                    }
                  }}
                />
              )}
            />

            <Button
              size="large"
              onPress={() => navigation.navigate("Landing", { landingId: "" })}
            >
              New Delivery
            </Button>
          </Layout>
        </CenteredContainer>
      </Layout>
      <SyncMultipleConfirmationModal />
      <SyncAllConfirmationModal />
    </Layout>
  );
};
