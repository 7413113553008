import { FC, useState } from "react";
import { Layout, Button, Input } from "@ui-kitten/components";
import { Platform } from "react-native";
import { Icon } from "./Icon";
import {
  faMinusCircle,
  faPlusCircle,
} from "@fortawesome/pro-regular-svg-icons";

interface CountInputProps {
  value: number;
  onChange: (n: number) => void;
  min?: number;
  max?: number;
  step?: number;
}

export const CountInput: FC<CountInputProps> = ({
  value,
  onChange,
  min = 0,
  max = 999999999,
  step = 1,
}: CountInputProps) => {
  const [numericInputIsFocused, setNumericInputIsFocused] =
    useState<boolean>(false);
  const [focusedInputValue, setFocusedInputValue] = useState<string>();

  return (
    <Layout style={{ flexDirection: "row", alignItems: "center" }}>
      <Button
        disabled={value <= min}
        appearance="outline"
        size={Platform.OS === "web" ? "medium" : "tiny"}
        accessoryLeft={(props) => <Icon icon={faMinusCircle} />}
        onPress={() => onChange(Math.max(value - step, min))}
      ></Button>
      <Input
        keyboardType="numeric"
        textAlign="center"
        style={{ marginHorizontal: 5 }}
        size={Platform.OS === "web" ? "giant" : "small"}
        value={numericInputIsFocused ? focusedInputValue : `${value}`}
        onFocus={() => {
          setNumericInputIsFocused(true);
        }}
        onBlur={() => {
          setNumericInputIsFocused(false);

          if (!focusedInputValue) {
            setFocusedInputValue(`${value}`);
            return;
          }

          const n = parseInt(focusedInputValue, 10);
          if (!Number.isSafeInteger(n)) {
            setFocusedInputValue(`${value}`);
            return;
          }

          if (n < min || n > max) {
            setFocusedInputValue(`${value}`);
            return;
          }

          onChange(n);
        }}
        onChangeText={(value: string) => {
          setFocusedInputValue(value);
        }}
      />
      <Button
        disabled={value >= max}
        appearance="outline"
        size={Platform.OS === "web" ? "medium" : "tiny"}
        accessoryLeft={(props) => <Icon icon={faPlusCircle} />}
        onPress={() => onChange(Math.min(value + step, max))}
      ></Button>
    </Layout>
  );
};
