import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  FontAwesomeIcon,
  FontAwesomeIconStyle,
} from "@fortawesome/react-native-fontawesome";
import { FC } from "react";
import { View, ViewStyle } from "react-native";

export interface IconProps {
  size?: number;
  color?: string;
  icon: IconProp;
  rotate?: number;
  style?: ViewStyle;
}

export const Icon: FC<IconProps> = ({
  size = 24,
  icon,
  color = "#000",
  rotate = 0,
  style = {},
}) => {
  return (
    <View style={style}>
      <FontAwesomeIcon
        size={size}
        icon={icon}
        color={color}
        style={
          {
            transform: [{ rotate: `${rotate}deg` }],
            outline: "none",
            outlineStyle: "none",
            fill: color,
          } as FontAwesomeIconStyle
        }
      />
    </View>
  );
};
