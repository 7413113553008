import { useContext, createContext, PropsWithChildren } from "react";
import { useStorage } from "../lib/storage";
import { useNetworkContext } from "./networkProvider";
import { useApi } from "../api";

export interface ISyncContext {
  lastSyncedAt?: number;
  isOnline: boolean;
}

const SyncContext = createContext<ISyncContext>({
  isOnline: false,
});

const SYNC_INTERVAL = 1000 * 60 * 10;

export const SyncContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const { Fisher, Species, StagedDelivery, RSBaitOrder } = useApi();
  const { isOnline } = useNetworkContext();
  const [lastSyncedAt, setLastSyncedAt] = useStorage<number>("synced_at");

  Fisher.all();
  Species.all();
  StagedDelivery.search();
  RSBaitOrder.search();

  return (
    <SyncContext.Provider value={{ lastSyncedAt, isOnline }}>
      {children}
    </SyncContext.Provider>
  );
};

export const useSyncContext = () => useContext(SyncContext);
