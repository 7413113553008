import { Platform } from "react-native";
import { FC, PropsWithChildren } from "react";
import { Layout } from "@ui-kitten/components";

interface CenteredContainerProps {
  level?: string;
}

export const CenteredContainer: FC<
  PropsWithChildren<CenteredContainerProps>
> = ({ children, level }: PropsWithChildren<CenteredContainerProps>) => {
  if (Platform.OS !== "web") {
    return <>{children}</>;
  }
  return (
    <Layout level={level} style={{ flex: 1, flexDirection: "row" }}>
      <Layout level={level} style={{ flex: 1 }}></Layout>
      <Layout level={level} style={{ flex: 1, flexGrow: 3 }}>
        {children}
      </Layout>
      <Layout level={level} style={{ flex: 1 }}></Layout>
    </Layout>
  );
};
