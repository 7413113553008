import { createApiModel, Type, Static } from "@tracktile/axiom";
import { User } from "./users";
import { Species } from "./species";
import { Fisher } from "./fishers";
import { Station } from "./stations";
import { Vessel } from "./vessel";

export const StagedDeliveryItem = Type.Object({
  id: Type.String(),
  position: Type.Number(),
  species: Type.Pick(Species.schema, ["id", "name"]),
  count: Type.Number(),
  metadata: Type.Partial(
    Type.Object({
      tagNumber: Type.String(),
    })
  ),
});

export type StagedDeliveryItem = Static<typeof StagedDeliveryItem>;

export const StagedDelivery = createApiModel({
  name: "stagedDelivery",
  resource: "/deliveries/staged",
  schema: Type.Object({
    id: Type.String(),
    isNew: Type.Optional(Type.Boolean()),
    fishers: Type.Array(Fisher.schema),
    items: Type.Array(StagedDeliveryItem),
    batchNumber: Type.String(),
    slipNumber: Type.String(),
    slipDate: Type.Optional(Type.String()),
    locationKey: Type.Optional(Type.String()),
    buyingStation: Type.Optional(Station.schema),
    date: Type.String({ format: "date-time" }),
    user: User.schema,
    vessel: Type.Optional(Vessel.schema),
    createdAt: Type.Number(),
    updatedAt: Type.Number(),
    signatureId: Type.Optional(Type.String()),
    sbmDeliveryId: Type.Optional(Type.String()),
    syncedToSBM: Type.Boolean(),
    syncedToBackend: Type.Boolean(),
  }),
});

export type StagedDelivery = Static<typeof StagedDelivery.schema>;
