import { createApiModel, Type, Static } from "@tracktile/axiom";

export const Vessel = createApiModel({
  name: "vessels",
  resource: "/vessels",
  schema: Type.Object({
    id: Type.Optional(Type.Number()),
    name: Type.String(),
    number: Type.String(),
    active: Type.Number(),
    dynamoId: Type.Optional(Type.String()),
    processedBackOffice: Type.Number(),
  }),
});

export type Vessel = Static<typeof Vessel.schema>;
