import { FlatList } from "react-native";
import { FlashList } from "@shopify/flash-list";
import { useState, useMemo } from "react";
import FuzzySearch from "fuzzy-search";
import { TouchableHighlight } from "react-native";
import {
  Layout,
  Button,
  Text,
  CheckBox,
  Input,
  StyleService,
  useStyleSheet,
} from "@ui-kitten/components";

import { useApi, RSBaitFisher } from "../api";
import { Icon } from "./Icon";
import {
  faCheckCircle,
  faClose,
  faHashtag,
} from "@fortawesome/pro-regular-svg-icons";

interface FisherSelectionModalProps {
  onSelect: (ids: (string | number)[]) => void;
  selected?: (string | number)[];
  multi?: boolean;
  selectKey?: string;
  hideIds?: string[];
}

const themedStyles = StyleService.create({
  fisherButton: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: "color-primary-transparent-100",
    borderStyle: "solid",
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "color-primary-transparent-600",
  },
  fisherButtonHighlight: {
    backgroundColor: "color-primary-transparent-200",
  },
  fisherButtonText: {
    color: "color-primary-transparent-600",
    fontWeight: "bold",
  },
});

export function FisherSelectionModal({
  onSelect,
  selected = [],
  multi = false,
  selectKey = "counterPointId",
}: FisherSelectionModalProps) {
  const api = useApi();
  const theme = useStyleSheet(themedStyles);
  const [searchString, setSearchString] = useState<string>("");
  const { data: fishers = [] } = api.RSBaitFisher.all();
  const [selectedKeys, setSelectedKeys] =
    useState<(number | string)[]>(selected);

  const enabledFishers = useMemo(
    () => fishers.filter(({ enabled }) => !!enabled),
    [fishers]
  );

  const fisherSearcher = useMemo(
    () =>
      new FuzzySearch(
        enabledFishers,
        ["firstName", "lastName", "email", "counterPointId"],
        {
          caseSensitive: false,
        }
      ),
    [enabledFishers]
  );

  const filteredFishers = useMemo(
    () =>
      fisherSearcher
        .search(searchString)
        .sort(
          (
            { lastName: lastNameA, firstName: firstNameA },
            { lastName: lastNameB, firstName: firstNameB }
          ) => {
            if (lastNameA && lastNameB) {
              let [a, b] = [lastNameA, lastNameB];
              return a < b ? -1 : a > b ? 1 : 0;
            }
            if (lastNameA && !lastNameB) {
              return -1;
            }
            if (lastNameB && !lastNameA) {
              return 1;
            } else {
              let [a, b] = [firstNameA, firstNameB];
              return a < b ? -1 : a > b ? 1 : 0;
            }
          }
        ),
    [fisherSearcher, searchString]
  );

  const handleFisherPressed = (fisher: RSBaitFisher) => {
    if (multi) {
      if (
        selectedKeys.includes(
          (fisher as {} as Record<string, string>)?.[selectKey]
        )
      ) {
        setSelectedKeys(
          selectedKeys.filter(
            (id) => id !== (fisher as {} as Record<string, string>)[selectKey]
          )
        );
      } else {
        setSelectedKeys([
          ...selectedKeys,
          (fisher as {} as Record<string, string>)[selectKey],
        ]);
      }
    } else {
      onSelect([(fisher as {} as Record<string, string>)[selectKey]]);
    }
  };

  const handleFisherCheckboxChanged = (
    state: boolean,
    fisherCounterPointId: number
  ) => {
    if (state) {
      setSelectedKeys([...selectedKeys, fisherCounterPointId]);
    } else {
      setSelectedKeys(
        selectedKeys.filter(
          (counterPointId) => counterPointId !== fisherCounterPointId
        )
      );
    }
  };

  return (
    <Layout
      style={{
        flex: 1,
        flexDirection: "column",
      }}
    >
      <Layout style={{ flexGrow: 0, flexDirection: "row" }}>
        <Layout
          style={{
            marginVertical: 24,
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Input
            size="large"
            style={{ flex: 1 }}
            placeholder="Search Fishers..."
            onChangeText={setSearchString}
            value={searchString}
          />
          {multi && (
            <Button
              appearance="ghost"
              size="large"
              style={{ marginLeft: 18 }}
              disabled={selectedKeys.length === 0}
              accessoryLeft={(props) => (
                <Icon
                  icon={
                    selectedKeys.length === 0
                      ? faCheckCircle
                      : selectedKeys.length === fishers.length
                      ? faClose
                      : faClose
                  }
                />
              )}
              onPress={() => {
                if (selectedKeys.length === 0) {
                  setSelectedKeys(
                    fishers.map(
                      (fisher) =>
                        (fisher as {} as Record<string, string>)?.[selectKey]
                    )
                  );
                } else {
                  setSelectedKeys([]);
                }
              }}
            >
              Clear Selection
            </Button>
          )}
        </Layout>
      </Layout>
      <FlashList
        style={{ flex: 1, paddingHorizontal: 18, paddingBottom: 18 }}
        data={filteredFishers}
        keyExtractor={({ firstName, lastName }, index) =>
          `${firstName}${lastName}${index}`
        }
        renderItem={({ item: fisher }: { item: RSBaitFisher }) => {
          return (
            <Layout
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                paddingVertical: 6,
              }}
            >
              <Layout
                style={{
                  flex: 1,
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {multi && (
                  <>
                    <Layout
                      style={{
                        flex: 1,
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingVertical: 8,
                      }}
                    >
                      <CheckBox
                        style={{ marginVertical: 5, marginRight: 10 }}
                        checked={selectedKeys.includes(fisher.counterPointId)}
                        onChange={(state: boolean) =>
                          handleFisherCheckboxChanged(
                            state,
                            fisher.counterPointId
                          )
                        }
                      ></CheckBox>
                      <Text category="h7" style={theme.fisherButtonText}>
                        {fisher.lastName
                          ? `${fisher.lastName}, ${fisher.firstName}`
                          : fisher.firstName}
                      </Text>
                      <Layout style={{ flex: 1 }} />
                      <Text style={theme.fisherButtonText}>
                        {fisher.companyName}
                      </Text>
                      <Icon icon={faHashtag} size={32} color="#EEE" />
                      <Text style={theme.fisherButtonText}>
                        {fisher.counterPointId}
                      </Text>
                    </Layout>
                  </>
                )}
                {!multi && (
                  <TouchableHighlight
                    style={theme.fisherButton}
                    activeOpacity={0.6}
                    underlayColor={theme.fisherButtonHighlight.backgroundColor}
                    onPress={() => handleFisherPressed(fisher)}
                  >
                    <>
                      <Text style={theme.fisherButtonText}>
                        {fisher.lastName
                          ? `${fisher.lastName}, ${fisher.firstName}`
                          : fisher.firstName}
                      </Text>
                      <Layout style={{ flex: 1 }} />
                      <Text style={theme.fisherButtonText}>
                        {fisher.companyName}
                      </Text>
                      <Icon icon={faHashtag} size={32} color="#EEE" />
                      <Text style={theme.fisherButtonText}>
                        {fisher.counterPointId}
                      </Text>
                    </>
                  </TouchableHighlight>
                )}
              </Layout>
            </Layout>
          );
        }}
      />
      {multi && (
        <Layout
          style={{
            display: "flex",
            flexDirection: "row",
            flexGrow: 0,
            justifyContent: "space-between",
            marginTop: 18,
          }}
        >
          <Button
            status="primary"
            size="giant"
            style={{ flexGrow: 1, marginBottom: 18 }}
            onPress={() => {
              onSelect(selectedKeys);
              setSelectedKeys([]);
            }}
          >
            {((selectedKeys.length === 0 ||
              selectedKeys.length === fishers.length) &&
              "View All Orders") ||
              `View Orders From ${selectedKeys.length} Fishers`}
          </Button>
        </Layout>
      )}
    </Layout>
  );
}
