import { View, FlatList, TouchableHighlight } from "react-native";
import { FlashList } from "@shopify/flash-list";
import FuzzySearch from "fuzzy-search";
import { useState, useMemo } from "react";

import {
  Layout,
  Button,
  Text,
  CheckBox,
  Input,
  StyleService,
  useStyleSheet,
} from "@ui-kitten/components";

const themedStyles = StyleService.create({
  selectionItem: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: "color-primary-transparent-100",
    borderStyle: "solid",
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "color-primary-transparent-600",
    marginBottom: 12,
  },
});

interface SelectionModalProps<T extends Record<string, unknown>> {
  onSelect: (ids: (string | number)[]) => void;
  selected?: (string | number)[];
  multi?: boolean;
  selectKey?: keyof T;
  items: T[];
  renderItem?: (item: T) => React.ReactNode;
  searchKey?: keyof T;
  hideSearch?: boolean;
}

export function SelectionModal<T extends Record<string, unknown>>({
  onSelect,
  selected = [],
  multi = false,
  selectKey = "id",
  items = [] as T[],
  renderItem,
  searchKey = "name",
  hideSearch = false,
}: SelectionModalProps<T>) {
  const theme = useStyleSheet(themedStyles);
  const [searchString, setSearchString] = useState<string>("");
  const [selectedKeys, setSelectedKeys] =
    useState<(number | string)[]>(selected);

  const searcher = useMemo(
    () => new FuzzySearch<T>(items, [String(searchKey)]),
    [items]
  );

  const filteredItems = useMemo(() => {
    if (!searchString) {
      return items;
    }
    return searcher.search(searchString);
  }, [searchString, items]);

  const handleSelect = (item: T) => {
    if (multi) {
      if (selectedKeys.includes(String(item[String(selectKey)]))) {
        setSelectedKeys(
          selectedKeys.filter((k) => String(k) !== String(item[selectKey]))
        );
      } else {
        setSelectedKeys([...selectedKeys, String(item[String(selectKey)])]);
      }
    } else {
      onSelect([String(item[String(selectKey)])]);
    }
  };

  const handleDone = () => {
    onSelect(selectedKeys);
  };

  const handleSearch = (value: string) => {
    setSearchString(value);
  };

  return (
    <Layout style={{ flex: 1, paddingHorizontal: 12, paddingVertical: 24 }}>
      {!hideSearch && (
        <Input
          placeholder="Search"
          value={searchString}
          onChangeText={handleSearch}
        />
      )}
      <FlashList
        style={{ marginVertical: 24 }}
        data={filteredItems}
        keyExtractor={(item) => String(item[selectKey])}
        ListEmptyComponent={() => <Text>No items found</Text>}
        renderItem={({ item }) => (
          <TouchableHighlight
            style={theme.selectionItem}
            onPress={() => handleSelect(item)}
          >
            <View
              style={{
                flexGrow: 1,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <View style={{ flexGrow: 1 }}>{renderItem?.(item)}</View>
              {multi && (
                <CheckBox
                  checked={selectedKeys.includes(String(item[selectKey]))}
                  onChange={() => handleSelect(item)}
                />
              )}
            </View>
          </TouchableHighlight>
        )}
      />
      {/* <Layout
        style={{
          flexGrow: 1,
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        <Button onPress={handleDone}>Done</Button>
      </Layout> */}
    </Layout>
  );
}
