import {
  useQuery,
  UseQueryOptions,
  useMutation,
  UseMutationOptions,
} from "@tanstack/react-query";
import { request, Static } from "@tracktile/axiom";

import { getConfig } from "../config";
import { useStorage } from "../lib/storage";
import { User } from "./models/users";

const { api } = getConfig();

export type CommonLoginParams = {
  password: string;
};

export type AppUserLoginParams = {
  email: string;
};

export type SBMUserLoginParams = {
  username: string;
  domain: string;
};

export type LoginParams = CommonLoginParams &
  Partial<AppUserLoginParams> &
  Partial<SBMUserLoginParams>;

export const Login = (
  options?: UseMutationOptions<{ token: string }, unknown, LoginParams>
) => {
  const [token] = useStorage<string>("token");
  return useMutation({
    mutationKey: ["login"],
    mutationFn: async (params: LoginParams) => {
      const { email, username, domain, password } = params;
      if (email && password) {
        const [data] = await request<LoginParams, { token: string }>(
          `${api}/auth`,
          {
            method: "POST",
            body: { email, password },
            token,
          }
        );
        return data;
      } else {
        const [data] = await request<LoginParams, { token: string }>(
          `${api}/auth`,
          {
            method: "POST",
            body: { domain, username, password },
            token,
          }
        );
        return data;
      }
    },
    ...options,
  });
};

export const Identity = (options: Partial<UseQueryOptions>) => {
  const [token] = useStorage<string>("token");
  return useQuery({
    queryKey: ["identity", { token }],
    queryFn: async () => {
      const [data] = await request<Static<typeof User.schema>>(`${api}/auth`, {
        method: "GET",
        token,
      });
      return data;
    },
  });
};
