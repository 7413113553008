import moment, { Moment } from "moment";

export type DateRangeSelectionOption =
  | "today"
  | "yesterday"
  | "last_7_days"
  | "custom_day"
  | "custom_range";

export const DateRangeLabelMap: Record<DateRangeSelectionOption, string> = {
  today: "Today",
  yesterday: "Yesterday",
  last_7_days: "Last 7 Days",
  custom_day: "Single Day",
  custom_range: "Day Range",
};

export const dateRangeSelectionMap: () => Record<
  DateRangeSelectionOption,
  { startDate: Moment; endDate: Moment }
> = () => ({
  today: {
    startDate: moment().startOf("day"),
    endDate: moment().endOf("day"),
  },
  yesterday: {
    startDate: moment().subtract(1, "day").startOf("day"),
    endDate: moment().subtract(1, "day").endOf("day"),
  },
  last_7_days: {
    startDate: moment().endOf("day").subtract(7, "days").startOf("day"),
    endDate: moment().endOf("day"),
  },
  custom_day: {
    startDate: moment().startOf("day"),
    endDate: moment().endOf("day"),
  },
  custom_range: {
    startDate: moment().subtract(3, "day").startOf("day"),
    endDate: moment().endOf("day"),
  },
});
