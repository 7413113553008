import { createContext, useContext, PropsWithChildren } from "react";

import { useApi, Station } from "../api";
import { useStorage } from "../lib/storage";

interface TStationsContext {
  stations: Station[];
  currentStation?: Station;
  setCurrentStation: (station: Station) => void;
}

const DEFAULT_STATION = { id: "", name: "", statsDistrict: "", active: 0 };

export const StationsContext = createContext<TStationsContext>({
  stations: [],
  currentStation: DEFAULT_STATION,
  setCurrentStation: () => {},
});

export const StationsProvider = ({ children }: PropsWithChildren) => {
  const { Station } = useApi();
  const { data: stations = [] } = Station.all();
  const activeStations = stations.filter((station) => !!station.active);

  const [currentStation, setCurrentStation] = useStorage<Station>(
    "@CurrentStation",
    activeStations?.[0] ?? DEFAULT_STATION
  );

  return (
    <StationsContext.Provider
      value={{ stations: activeStations, currentStation, setCurrentStation }}
    >
      {children}
    </StationsContext.Provider>
  );
};

export const useStations = () => {
  return useContext(StationsContext);
};
