import moment from "moment";
import { useMemo, Fragment } from "react";
import { Dimensions, Platform, StyleProp } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import {
  Layout,
  Button,
  Text,
  Divider,
  StyleService,
  useStyleSheet,
  useTheme,
} from "@ui-kitten/components";

import { Icon } from "../components";
import { StagedDelivery, Fisher } from "../api";
import { LandingsStackParamsList } from "../screens";
import { useConfirmation } from "../hooks";
import { Icon as RNIcon } from "./Icon";
import { useLanding } from "../context/landingProvider";
import { useAuth } from "../context/authProvider";
import {
  faAnchor,
  faCalendar,
  faCheckCircle,
  faCheckDouble,
  faClock,
  faClose,
  faEdit,
  faFileInvoice,
  faGlobe,
  faMapPin,
  faPerson,
  faRows,
  faShip,
  faTag,
  faWarning,
} from "@fortawesome/pro-regular-svg-icons";

const themedStyles = StyleService.create({
  container: {
    marginVertical: 20,
    marginHorizontal: 40,
    flexDirection: "column",
  },
  header: {
    flexDirection: "row",
    margin: 0,
    marginTop: 12,
    paddingHorizontal: 24,
  },
  headerRow: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 20,
    // marginBottom: Platform.OS === "web" ? 0 : 10,
  },
  headerRowLast: {
    flexDirection: "row",
    alignItems: "center",
  },
  headerRowIcon: {
    width: 22,
    height: 22,
    marginRight: 5,
    textAlign: "center",
    alignSelf: "center",
    justifyContent: "center",
  },
  headerContext: {
    flexDirection: "row",
    flexWrap: "wrap",
    lineHeight: 24,
    paddingHorizontal: 24,
    paddingVertical: 12,
  },
  fisherBadge: {
    backgroundColor: "background-basic-color-3",
    padding: 5,
    borderRadius: 10,
    margin: 5,
  },
  itemRow: {
    flexDirection: "row",
    alignContent: "space-between",
    padding: 10,
    marginLeft: 20,
  },
  itemName: {
    width: Platform.OS === "web" ? 400 : Dimensions.get("window").width - 180,
    alignSelf: "stretch",
  },
  itemCount: {
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
    flexDirection: "row",
    marginRight: 20,
  },
  summary: {
    flexDirection: "column",
    paddingHorizontal: 24,
    paddingBottom: 8,
  },
  footer: {
    padding: 8,
    flexDirection: "row",
    alignItems: "center",
  },
});

interface LandingCardProps
  extends Pick<NativeStackScreenProps<LandingsStackParamsList>, "navigation"> {
  delivery: StagedDelivery;
  style?: StyleProp<{}>;
  isCheckboxVisible?: Boolean;
  isCheckboxSelected?: Boolean;
  onCheckboxChange?: Function;
}

const toName = ({ companyName, firstName, lastName }: Fisher) => {
  if (companyName) {
    return companyName;
  }
  if (firstName && lastName) {
    return `${lastName}, ${firstName}`;
  }
  if (!lastName && firstName) {
    return firstName;
  }
  if (lastName && !firstName) {
    return lastName;
  }
};

export const LandingCard = ({
  delivery,
  style = {},
  navigation,
  isCheckboxVisible = false,
  isCheckboxSelected = false,
  onCheckboxChange,
}: LandingCardProps) => {
  const { tenant } = useAuth();
  const styles = useStyleSheet(themedStyles);
  const theme = useTheme();
  const {
    id: deliveryId,
    fishers,
    items,
    date,
    user,
    batchNumber,
    buyingStation,
    vessel,
    syncedToBackend = true,
    syncedToSBM,
    createdAt,
    updatedAt,
    slipNumber,
    signatureId,
  } = delivery;

  const { syncLandingToSBM } = useLanding();

  const sortedItems = useMemo(
    () =>
      items.sort(({ count: a }, { count: b }) => (a > b ? -1 : a < b ? 1 : 0)),
    [items]
  );

  const { Modal: SyncConfirmationModal, show: showSyncModal } = useConfirmation(
    {
      title: "Sync to Fisher Account?",
      description:
        "This will upload the details to the fisher accounts. Once synchronized, you cannot edit or delete this delivery. Are you sure?",
      cancelText: "Go Back",
      confirmText: "Sync Delivery to SBM",
      onConfirm: () => {
        syncLandingToSBM(deliveryId);
      },
    }
  );

  return (
    <Layout key={`${deliveryId}-${updatedAt}`} style={styles.container}>
      <Layout style={styles.header}>
        <Layout style={styles.headerRow}>
          <Icon icon={faRows} size={16} style={{ ...styles.headerRowIcon }} />
          <Text category={Platform.OS === "web" ? "s1" : "c2"}>
            {batchNumber}
          </Text>
        </Layout>
        <Layout style={styles.headerRow}>
          <Icon
            icon={faFileInvoice}
            size={16}
            style={{ ...styles.headerRowIcon }}
          />
          <Text category={Platform.OS === "web" ? "s1" : "c2"}>
            {slipNumber}
          </Text>
        </Layout>
        {vessel && (
          <Layout style={styles.headerRow}>
            <Icon icon={faShip} size={16} style={{ ...styles.headerRowIcon }} />
            <Text category={Platform.OS === "web" ? "s1" : "c2"}>
              {vessel.name}
            </Text>
          </Layout>
        )}
      </Layout>
      <Layout style={styles.headerContext}>
        <Icon icon={faAnchor} size={16} style={{ ...styles.headerRowIcon }} />
        {fishers.map((fisher) => (
          <Layout key={fisher.id} style={styles.fisherBadge}>
            <Text category={Platform.OS === "web" ? "s1" : "c2"}>
              {toName(fisher)}
            </Text>
          </Layout>
        ))}
      </Layout>
      <Layout style={styles.headerContext}>
        {Platform.OS === "web" && (
          <Layout style={styles.headerRow}>
            <Icon
              icon={faPerson}
              color={theme["background-alternative-color-2"]}
              style={{ ...styles.headerRowIcon }}
            />
            <Text category="s1">{user.firstName}</Text>
          </Layout>
        )}
        <Layout style={styles.headerRow}>
          <Icon
            icon={faMapPin}
            color={theme["background-alternative-color-2"]}
            style={{ ...styles.headerRowIcon }}
          />
          <Text category={Platform.OS === "web" ? "s1" : "c2"}>
            {buyingStation?.name}
          </Text>
        </Layout>

        <Layout style={styles.headerRow}>
          <Icon
            icon={faCalendar}
            color={theme["background-alternative-color-2"]}
            style={{ ...styles.headerRowIcon }}
          ></Icon>
          <Text category={Platform.OS === "web" ? "s1" : "c2"}>
            {createdAt ? moment(createdAt).format("DD/MM/YYYY") : ""}
          </Text>
        </Layout>
        <Layout style={styles.headerRowLast}>
          <Icon
            icon={faClock}
            color={theme["background-alternative-color-2"]}
            style={styles.headerRowIcon}
          ></Icon>
          <Text category={Platform.OS === "web" ? "s1" : "c2"}>
            {createdAt ? moment(createdAt).format("h:mm A") : ""}
          </Text>
        </Layout>
        <Layout style={{ flex: 1 }}></Layout>
      </Layout>
      <Divider />
      <Layout
        style={{
          alignSelf: "stretch",
          marginBottom: 5,
        }}
      >
        {sortedItems.map(
          (
            { species: { id, name }, metadata: { tagNumber }, count },
            index
          ) => (
            <Fragment key={`${id}-${index}`}>
              <Layout style={styles.itemRow}>
                <Layout style={styles.itemName}>
                  {Platform.OS === "web" ? (
                    <Text category="h6">{name}</Text>
                  ) : (
                    <Text
                      category="c1"
                      style={{ width: Dimensions.get("window").width / 2.5 }}
                      numberOfLines={4}
                    >
                      {name}
                    </Text>
                  )}
                  {!!tenant?.account?.tunaFunctionality && !!tagNumber && (
                    <Layout
                      style={{
                        flexDirection: "row",
                        marginTop: 12,
                        alignItems: "center",
                      }}
                    >
                      <Icon
                        icon={faTag}
                        style={{ marginRight: 12 }}
                        size={18}
                      ></Icon>
                      <Text category="s1">{tagNumber}</Text>
                    </Layout>
                  )}
                </Layout>
                <Layout
                  style={{
                    flex: 1,
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Icon
                    icon={faClose}
                    color={theme["background-alternative-color-2"]}
                    style={styles.headerRowIcon}
                  ></Icon>
                  <Layout style={styles.itemCount}>
                    <Text category="h6">{count}</Text>
                  </Layout>
                </Layout>
              </Layout>
            </Fragment>
          )
        )}
      </Layout>
      {!isCheckboxVisible && (
        <Layout style={styles.footer} level="3">
          <Layout level="3">
            <Button
              appearance="ghost"
              status={
                !syncedToBackend ? "warning" : syncedToSBM ? "basic" : "primary"
              }
              size={Platform.OS === "web" ? "medium" : "tiny"}
              accessoryLeft={() => (
                <Icon
                  icon={
                    !syncedToBackend
                      ? faWarning
                      : syncedToSBM
                      ? faCheckCircle
                      : faGlobe
                  }
                />
              )}
              onPress={() => {
                showSyncModal();
              }}
              disabled={syncedToSBM || !syncedToBackend}
            >
              {!syncedToBackend
                ? "NOT SAVED TO OFFICE"
                : syncedToSBM
                ? "SAVED TO SBM"
                : "SYNC TO SBM"}
            </Button>
          </Layout>
          <Layout level="3" style={{ flex: 1 }}></Layout>
          <Layout style={{ marginRight: 10 }}>
            {signatureId ? (
              <Button
                appearance="ghost"
                status="success"
                size={Platform.OS === "web" ? "medium" : "tiny"}
                accessoryLeft={() => <Icon icon={faCheckCircle} />}
                onPress={() => {
                  navigation.navigate("Signature", {
                    signatureId: signatureId,
                    landing: delivery,
                  });
                }}
              >
                SIGNED
              </Button>
            ) : (
              <Button
                appearance="ghost"
                size={Platform.OS === "web" ? "medium" : "tiny"}
                status="primary"
                accessoryLeft={() => <Icon icon={faCheckCircle} />}
                onPress={() => {
                  navigation.navigate("Signature", {
                    signatureId: undefined,
                    landing: delivery,
                  });
                }}
              >
                SIGN
              </Button>
            )}
          </Layout>
          <Layout>
            <Button
              appearance="ghost"
              size={Platform.OS === "web" ? "medium" : "tiny"}
              status="primary"
              accessoryLeft={() => <Icon icon={faEdit} />}
              onPress={() => {
                navigation.navigate("Landing", { landingId: deliveryId });
              }}
              disabled={syncedToSBM}
            >
              {Platform.OS === "web" ? "EDIT DELIVERY" : "EDIT"}
            </Button>
          </Layout>
        </Layout>
      )}
      {isCheckboxVisible && (
        <Layout style={{ flex: 1 }}>
          <Button
            appearance={isCheckboxSelected ? "filled" : "ghost"}
            status="info"
            accessoryLeft={(props) => (
              <Icon icon={isCheckboxSelected ? faCheckCircle : faCheckDouble} />
            )}
            onPress={() => {
              onCheckboxChange &&
                onCheckboxChange(!isCheckboxSelected, deliveryId);
            }}
          >
            {isCheckboxSelected ? "SELECTED" : "SELECT"}
          </Button>
        </Layout>
      )}
      <SyncConfirmationModal />
    </Layout>
  );
};
