import { Pressable, StyleProp, ViewStyle } from "react-native";
import {
  Text,
  Layout,
  StyleService,
  useStyleSheet,
} from "@ui-kitten/components";

interface PressableCardProps {
  title: string;
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
}

const themedStyled = StyleService.create({
  container: {},
});

export const PressableCard = ({
  title,
  onPress = () => {},
  style,
}: PressableCardProps) => {
  const styles = useStyleSheet(themedStyled);
  return (
    <Pressable onPress={onPress} style={style}>
      <Layout style={styles.container}>
        <Text>{title}</Text>
      </Layout>
    </Pressable>
  );
};
