import {
  Layout,
  Button,
  Card,
  Datepicker,
  Text,
  Input,
  Divider,
} from "@ui-kitten/components";
import React, { useState, useEffect, Fragment } from "react";
import {
  ScrollView,
  Platform,
  View,
  Dimensions,
  KeyboardAvoidingView,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import { useApi, StagedDeliveryItem, Fisher, Vessel } from "../../api";
import { RootScreenProps } from "../../nav";
import { useLanding } from "../../context/landingProvider";
import { useStations } from "../../context/stationsProvider";
import { useFisher } from "../../context/fisherProvider";
import { useVessel } from "../../context/vesselProvider";
import { useConfirmation, useModal, Modal } from "../../hooks";
import {
  SelectionModal,
  TopNavigation,
  CountInput,
  IconHeader,
  TouchableHighlight,
  CenteredContainer,
  Icon,
} from "../../components";
import {
  NativeStackNavigationProp,
  NativeStackScreenProps,
} from "@react-navigation/native-stack";
import { RootStackParamList } from "../../types";
import { useTenantLabels } from "../../context/tenantLabelsProvider";
import { TenantConfiguration, useAuth } from "../../context/authProvider";
import {
  faAnchor,
  faAngleLeft,
  faCalendar,
  faChevronLeft,
  faCity,
  faFish,
  faMapMarker,
  faPlus,
  faPlusCircle,
  faShip,
  faTrash,
} from "@fortawesome/pro-regular-svg-icons";

interface LandingSpeciesRowProps {
  item: StagedDeliveryItem;
  showSpeciesSelectionModal: (id?: string | number) => void;
  onSetItemMetadata: (meta: StagedDeliveryItem["metadata"]) => void;
  onSetCount: (count: number) => void;
  onRemove: () => void;
}

const LandingSpeciesRow = ({
  item: { id, count, species, metadata = {} },
  showSpeciesSelectionModal,
  onSetItemMetadata,
  onSetCount,
  onRemove,
}: LandingSpeciesRowProps) => {
  const { tenant } = useAuth();
  const { labels } = useTenantLabels();
  return (
    <View
      style={{
        paddingHorizontal: Platform.OS === "web" ? 24 : 0,
        flexDirection: Dimensions.get("window").width < 920 ? "column" : "row",
        marginHorizontal: 0,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <TouchableHighlight
        onPress={() => showSpeciesSelectionModal(id)}
        style={{
          display: "flex",
          padding: Platform.OS === "web" ? 24 : 9,
          width: Platform.OS === "web" ? "100%" : 140,
        }}
      >
        <Text
          category={Platform.OS === "web" ? "h5" : "p2"}
          numberOfLines={5}
        >{`${species.name}`}</Text>
      </TouchableHighlight>
      <View style={{ flex: 1, elevation: 0 }} />
      <Layout
        style={{
          flexDirection:
            Dimensions.get("window").width < 920 ? "column" : "row",
          alignItems: "center",
        }}
      >
        {!!tenant?.account?.tunaFunctionality && (
          <Layout
            style={{
              flex: 1,
              maxWidth: 200,
              margin: 12,
            }}
          >
            <Input
              label={labels?.deliveryNumber ?? "Tag Number"}
              value={metadata.tagNumber ?? ""}
              onChangeText={(value: string) =>
                onSetItemMetadata({ tagNumber: value })
              }
            />
          </Layout>
        )}
        {!!species && (
          <>
            <CountInput value={count} onChange={onSetCount} />
            <Text category="s1" style={{ marginLeft: 12 }}>
              LBs
            </Text>
          </>
        )}
        <Button
          appearance="ghost"
          status="danger"
          accessoryLeft={(props) => <Icon icon={faTrash} />}
          onPress={() => onRemove()}
        />
      </Layout>
    </View>
  );
};

interface LandingSpeciesMockRowProps {
  onNewItem: () => void;
}

const LandingSpeciesMockRow = ({ onNewItem }: LandingSpeciesMockRowProps) => (
  <View
    style={{
      flexDirection: "row",
      marginHorizontal: 0,
      alignItems: "center",
      marginTop: 10,
    }}
  >
    <View>
      <Button
        size={Platform.OS === "web" ? "large" : "medium"}
        appearance="filled"
        onPress={onNewItem}
        accessoryLeft={(props) => <Icon icon={faPlusCircle} />}
      >
        Add Species
      </Button>
    </View>
  </View>
);

const LandingFisherRow = ({
  fisher,
  showFisherSelectionModal,
  removeFisher,
  tenant,
}: {
  fisher: Fisher;
  showFisherSelectionModal: () => void;
  removeFisher: (fisher: Fisher) => void;
  tenant: TenantConfiguration | undefined;
}) => {
  const fisherHeader = [fisher.vesselName, fisher.companyName]
    .filter((x) => !!x)
    .join(" - ");
  return (
    <Layout
      style={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <TouchableHighlight
        onPress={showFisherSelectionModal}
        style={{
          display: "flex",
          flexDirection: "column",
          padding: 24,
          width: Dimensions.get("window").width - 150,
        }}
      >
        <>
          {tenant && tenant.account?.vesselManagement === false && (
            <Text category="label" style={{ marginBottom: 12 }}>
              {fisherHeader}
            </Text>
          )}
          <Text
            category={Platform.OS === "web" ? "h5" : "p1"}
            numberOfLines={4}
          >{`${fisher.firstName} ${fisher.lastName}`}</Text>
        </>
      </TouchableHighlight>
      <Button
        appearance="ghost"
        status="danger"
        accessoryLeft={(props) => <Icon icon={faTrash} />}
        onPress={() => removeFisher(fisher)}
      />
    </Layout>
  );
};

const LandingFisherMockRow = ({ onNewFisher }: { onNewFisher: () => void }) => (
  <View
    style={{
      flexDirection: "row",
      marginHorizontal: 0,
      alignItems: "center",
      marginTop: 10,
    }}
  >
    <View>
      <Button
        size={Platform.OS === "web" ? "large" : "medium"}
        appearance="filled"
        onPress={onNewFisher}
        accessoryLeft={(props) => <Icon icon={faPlusCircle} />}
      >
        Add Fisher
      </Button>
    </View>
  </View>
);

export const LandingScreen = ({
  route,
}: Omit<NativeStackScreenProps<RootScreenProps>, "navigation">) => {
  const api = useApi();
  const { tenant } = useAuth();
  const { currentStation } = useStations();
  const navigation =
    useNavigation<NativeStackNavigationProp<RootStackParamList>>();
  const { labels } = useTenantLabels();
  const landingId = (route.params as { landingId?: string })?.landingId;
  const {
    date,
    fishers,
    vessel,
    batchNumber,
    slipNumber,
    buyingStation,
    items,
    addItem,
    setBatchNumber,
    setSlipNumber,
    setMetadataForItem,
    addFisher,
    updateFisher,
    removeFisher,
    setSpeciesForItem,
    setCountForItem,
    removeItem,
    resetLanding,
    removeVessel,
    canCancelLanding,
    canCheckout,
    submitLanding,
    deleteLanding,
    getFishersFromVessel,
  } = useLanding(landingId);

  const {
    firstName,
    lastName,
    companyName,
    email,
    submitFisher,
    resetFisher,
    setFirstName,
    setLastName,
    setCompanyName,
    setEmail,
  } = useFisher();

  const { name, number, submitVessel, resetVessel, setName, setNumber } =
    useVessel();

  const { data: allFishers = [] } = api.Fisher.all();
  const { data: allSpecies = [] } = api.Species.all();
  const { data: allVessels = [] } = api.Vessel.all();

  const [speciesSelectionTargetId, setSpeciesSelectionTargetId] = useState<
    string | undefined
  >();
  const [fisherSelectionTargetId, setFisherSelectionTargetId] = useState<
    string | undefined
  >();
  const [isInlineCreate, setIsInlineCreate] = useState(false);

  const { Modal: CancelConfirmationModal, show: showCancelModal } =
    useConfirmation({
      title: "Cancel Landing?",
      description: "Landing will not be saved.",
      cancelText: "Go Back",
      confirmText: "Cancel Landing",
      onConfirm: () => {
        resetLanding();
        if (typeof landingId !== "undefined") {
          navigation.navigate("Landings");
        }
        api.StagedDelivery.invalidateAll();
      },
    });

  const { Modal: DeleteConfirmationModal, show: showDeleteModal } =
    useConfirmation({
      title: "Delete Landing?",
      description: "Once deleted, this landing cannot be recovered.",
      cancelText: "Go Back",
      confirmText: "Delete Landing",
      onConfirm: async () => {
        await deleteLanding();
        api.StagedDelivery.invalidateAll();
        navigation.navigate("Landings");
      },
    });

  const {
    modalProps: fisherSelectionModalProps,
    toggle: toggleFisherSelectionModal,
  } = useModal({
    title: "Select Fisher",
  });

  const {
    toggle: toggleSpeciesSelectionModal,
    modalProps: speciesSelectionModalProps,
  } = useModal({
    title: "Select Species",
  });

  const {
    toggle: toggleVesselSelectionModal,
    modalProps: vesselSelectionModalProps,
  } = useModal({
    title: "Select Vessel",
  });

  const handleFisherSelection = ([fisherId]: (string | number)[]) => {
    const fisher = allFishers.find(({ id }) => String(id) === String(fisherId));
    if (!fisher) {
      return;
    }
    if (fisherSelectionTargetId) {
      updateFisher(fisherSelectionTargetId, fisher);
    } else {
      addFisher(fisher);
    }
    toggleFisherSelectionModal();
  };

  const handleFisherCreatedInline = (fisher: Fisher) => {
    if (!fisher) {
      return;
    }

    addFisher(fisher);
    toggleFisherSelectionModal();
  };

  const handleSpeciesSelection = ([speciesId]: (string | number)[]) => {
    const species = allSpecies.find(
      ({ id }) => String(id) === String(speciesId)
    );
    if (!species) {
      return;
    }
    if (speciesSelectionTargetId) {
      setSpeciesForItem(species, speciesSelectionTargetId);
    } else {
      addItem(species);
    }
    toggleSpeciesSelectionModal();
  };

  const handleVesselSelection = ([vesselId]: (string | number)[]) => {
    const selectedVessel = allVessels.find(
      ({ id }) => String(id) === String(vesselId)
    );
    if (!selectedVessel) {
      return;
    }
    // Set fishers on the vessel
    getFishersFromVessel(selectedVessel);
    toggleVesselSelectionModal();
  };

  const handleVesselCreatedInline = (vessel: Vessel) => {
    if (!vessel) {
      return;
    }

    getFishersFromVessel(vessel);
    toggleVesselSelectionModal();
  };

  useEffect(() => {
    if (typeof landingId === "undefined") {
      toggleFisherSelectionModal();
    }
  }, []);

  return (
    <Layout level="4" style={{ flexGrow: 1 }}>
      <TopNavigation
        title={landingId ? `Edit Landing` : "New Landing"}
        navigation={navigation}
        showMenuAction
      />
      <Card style={{ paddingHorizontal: 0 }}>
        <CenteredContainer>
          <Layout
            style={{
              padding: 0,
              flexDirection: "row",
            }}
          >
            <Layout style={{ flexDirection: "row", flex: 1 }}>
              <Layout
                style={{
                  flex: 1,
                  maxWidth: 200,
                  paddingRight: 10,
                }}
              >
                <Input
                  label={labels?.batchNumber ?? "Batch Number"}
                  value={batchNumber}
                  onChangeText={setBatchNumber}
                />
              </Layout>
            </Layout>
            {!tenant?.account?.tunaFunctionality && (
              <Layout
                style={{
                  flex: 1,
                  maxWidth: 200,
                }}
              >
                <Input
                  label={labels?.deliveryNumber ?? "Slip Number"}
                  value={slipNumber}
                  onChangeText={setSlipNumber}
                />
              </Layout>
            )}
          </Layout>
        </CenteredContainer>
      </Card>
      <Card style={{ paddingHorizontal: 0 }}>
        <CenteredContainer>
          <Layout
            style={{
              padding: 0,
              flexDirection: "row",
            }}
          >
            <Layout style={{ flexDirection: "row", flex: 1 }}>
              <Layout
                style={{
                  flex: 1,
                  maxWidth: 200,
                  paddingRight: 10,
                }}
              >
                {buyingStation || landingId ? (
                  <Input
                    label="Buying Station"
                    value={
                      landingId ? buyingStation?.name : currentStation?.name
                    }
                    editable={false}
                  />
                ) : (
                  <>
                    <Text category="label" appearance="hint">
                      Buying Station
                    </Text>
                    <Button
                      appearance="outline"
                      accessoryLeft={(props) => <Icon icon={faMapMarker} />}
                      size="small"
                      onPress={() => {
                        navigation.navigate("StationSelection");
                      }}
                      style={{ marginTop: 5 }}
                    >
                      Select Station
                    </Button>
                  </>
                )}
              </Layout>
            </Layout>

            <Layout style={{ flex: 1, maxWidth: 200 }}>
              <Datepicker
                label={labels?.deliveryDate ?? "Landing Date"}
                status="info"
                date={new Date(date)}
                accessoryLeft={(props) => <Icon icon={faCalendar} size={16} />}
                disabled
              />
            </Layout>

            {landingId && (
              <Button
                appearance="outline"
                size="small"
                status="danger"
                style={{ marginLeft: 18 }}
                onPress={() => showDeleteModal()}
                accessoryLeft={(props) => <Icon icon={faTrash} />}
              ></Button>
            )}
          </Layout>
        </CenteredContainer>
      </Card>
      <ScrollView
        style={{
          flexGrow: 1,
          padding: 24,
          height: Dimensions.get("window").height / 2,
        }}
      >
        {tenant && tenant.account?.vesselManagement && (
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginTop: 12,
              marginBottom: 24,
            }}
          >
            <Icon icon={faShip} size={Platform.OS === "web" ? 28 : 24} />
            <Button
              style={{ marginLeft: 12 }}
              size={Platform.OS === "web" ? "large" : "medium"}
              appearance={vessel ? "filled" : "outline"}
              onPress={() => {
                toggleVesselSelectionModal();
              }}
            >
              {vessel ? vessel.name : "Choose Vessel"}
            </Button>
            {vessel && (
              <Button
                appearance="ghost"
                status="danger"
                accessoryLeft={(props) => <Icon icon={faTrash} />}
                onPress={() => removeVessel()}
              />
            )}
          </View>
        )}

        <IconHeader icon={faAnchor} text="Fishers" />
        <Layout style={{ padding: 14, flexGrow: 1, marginBottom: 24 }}>
          {fishers.map((fisher, index) => (
            <Fragment key={fisher.id}>
              <LandingFisherRow
                fisher={fisher}
                removeFisher={removeFisher}
                showFisherSelectionModal={() => {
                  setFisherSelectionTargetId(String(fisher.id));
                  toggleFisherSelectionModal();
                }}
                tenant={tenant}
              />
              <Divider />
            </Fragment>
          ))}
          <LandingFisherMockRow
            onNewFisher={() => {
              setFisherSelectionTargetId(undefined);
              toggleFisherSelectionModal();
            }}
          />
        </Layout>
        <IconHeader icon={faFish} text="Species" />
        <Layout style={{ padding: 12 }}>
          {items.map((item: StagedDeliveryItem, index) => (
            <Fragment key={item.id}>
              <LandingSpeciesRow
                item={item}
                showSpeciesSelectionModal={(id) => {
                  if (typeof id !== "undefined") {
                    setSpeciesSelectionTargetId(String(id));
                  }
                  toggleSpeciesSelectionModal();
                }}
                onSetCount={(count) => setCountForItem(count, item.id)}
                onSetItemMetadata={(metadata) =>
                  setMetadataForItem(metadata, item.id)
                }
                onRemove={() => removeItem(item.id)}
              />
              <Divider style={{ paddingHorizontal: 12 }} />
            </Fragment>
          ))}
          <LandingSpeciesMockRow
            onNewItem={() => {
              setSpeciesSelectionTargetId(undefined);
              toggleSpeciesSelectionModal();
            }}
          />
        </Layout>
        <View style={{ marginBottom: 150 }}></View>
      </ScrollView>
      <Layout
        style={{
          flexDirection: "row",
          flexGrow: 0,
          minHeight: 42,
        }}
      >
        <Layout
          style={{
            flex: 1,
          }}
        >
          {canCancelLanding && (
            <Button
              size={Platform.OS === "web" ? "giant" : "medium"}
              status="danger"
              accessoryLeft={(props) => <Icon icon={faTrash} />}
              onPress={() => showCancelModal()}
            >
              Cancel
            </Button>
          )}
          {!canCancelLanding && (
            <Button
              accessoryLeft={(props) => <Icon icon={faChevronLeft} />}
              onPress={() => {
                resetLanding();
                navigation.goBack();
              }}
              size={Platform.OS === "web" ? "giant" : "medium"}
            >
              Go Back
            </Button>
          )}
        </Layout>
        <Layout
          style={{
            flex: 1,
          }}
        >
          <Button
            accessoryLeft={(props) => <Icon icon={faPlusCircle} />}
            disabled={!canCheckout}
            size={Platform.OS === "web" ? "giant" : "medium"}
            onPress={() => {
              submitLanding();
            }}
          >
            {landingId ? "Update Landing" : "Submit Landing"}
          </Button>
        </Layout>
      </Layout>
      <Modal {...fisherSelectionModalProps}>
        <KeyboardAvoidingView
          behavior={Platform.OS === "ios" ? "padding" : "height"}
          style={{ flex: 1 }}
        >
          {isInlineCreate ? (
            <>
              <Layout style={{ flex: 1, flexDirection: "column", padding: 12 }}>
                <Layout>
                  <Text style={{ padding: 8 }}>Add New Fisher</Text>
                  <Input
                    style={{ padding: 8 }}
                    label={"First Name"}
                    value={firstName}
                    onChangeText={setFirstName}
                  />
                  <Input
                    style={{ padding: 8 }}
                    label={"Last Name"}
                    value={lastName}
                    onChangeText={setLastName}
                  />
                  <Input
                    style={{ padding: 8 }}
                    label={"Company Name (optional)"}
                    value={companyName}
                    onChangeText={setCompanyName}
                  />
                  <Input
                    style={{ padding: 8 }}
                    label={"E-mail (optional)"}
                    value={email}
                    onChangeText={setEmail}
                  />
                </Layout>
              </Layout>
            </>
          ) : (
            <SelectionModal
              selectKey="id"
              onSelect={handleFisherSelection}
              items={allFishers
                .filter((fisher) => !!fisher.enabled)
                .filter(({ id }) => !fishers.map(({ id }) => id).includes(id))}
              renderItem={(fisher) => (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: 12,
                  }}
                >
                  <View
                    style={{
                      display: "flex",
                      flexGrow: 1,
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text category="s1">{`${fisher.firstName} ${fisher.lastName}`}</Text>

                    {fisher.companyName && (
                      <View style={{ flexDirection: "row" }}>
                        <Icon icon={faCity} size={12} style={{ padding: 3 }} />
                        <Text category="s2">{fisher.companyName}</Text>
                      </View>
                    )}

                    {tenant && tenant.account?.vesselManagement === false && (
                      <>
                        {fisher.vesselName && (
                          <View style={{ flexDirection: "row" }}>
                            <Icon
                              icon={faShip}
                              size={12}
                              style={{ padding: 3 }}
                            />
                            <Text category="s2">{fisher.vesselName}</Text>
                          </View>
                        )}
                      </>
                    )}
                  </View>
                </View>
              )}
            />
          )}
          {isInlineCreate && (
            <Button
              onPress={async () => {
                const result = await submitFisher();
                if (result) {
                  handleFisherCreatedInline(result);
                  setIsInlineCreate(!isInlineCreate);
                }
              }}
              accessoryLeft={(props) => <Icon icon={faPlusCircle} />}
            >
              Submit
            </Button>
          )}
          <Button
            onPress={() => {
              setIsInlineCreate(!isInlineCreate);
              resetFisher();
            }}
            style={{ marginBottom: 12 }}
            appearance="ghost"
            accessoryLeft={(props) =>
              isInlineCreate ? (
                <Icon icon={faAngleLeft} />
              ) : (
                <Icon icon={faPlus} />
              )
            }
          >
            {isInlineCreate ? "Go Back" : "Add New Fisher"}
          </Button>
        </KeyboardAvoidingView>
      </Modal>
      <Modal {...speciesSelectionModalProps}>
        <SelectionModal
          selectKey="id"
          onSelect={handleSpeciesSelection}
          items={allSpecies
            .filter((species) => !!species.enabled)
            .filter(
              (species) => !species.name.toLowerCase().includes("price change")
            )
            .filter(
              ({ id }) => !items.map(({ species }) => species.id).includes(id)
            )}
          renderItem={(species) => (
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                padding: 12,
              }}
            >
              <View
                style={{
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <Text category="s1">{species.name}</Text>
              </View>
            </View>
          )}
        />
      </Modal>
      <Modal {...vesselSelectionModalProps}>
        <KeyboardAvoidingView
          behavior={Platform.OS === "ios" ? "padding" : "height"}
          style={{ flex: 1 }}
        >
          {isInlineCreate ? (
            <>
              <Layout style={{ flex: 1, flexDirection: "column", padding: 12 }}>
                <Layout>
                  <Text style={{ padding: 8 }}>Add New Vessel</Text>
                  <Input
                    style={{ padding: 8 }}
                    label={"Name"}
                    value={name}
                    onChangeText={setName}
                  />
                  <Input
                    style={{ padding: 8 }}
                    label={"Vessel Number"}
                    value={number}
                    onChangeText={setNumber}
                  />
                </Layout>
              </Layout>
            </>
          ) : (
            <SelectionModal
              selectKey="id"
              onSelect={handleVesselSelection}
              items={allVessels.filter((vessel) => !!vessel.active)}
              renderItem={(vessel) => (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: 12,
                  }}
                >
                  <View
                    style={{
                      display: "flex",
                      flexGrow: 1,
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text category="s1">{vessel.name}</Text>

                    {vessel.number && (
                      <View style={{ flexDirection: "row" }}>
                        <Icon icon={faShip} size={12} style={{ padding: 3 }} />
                        <Text category="s2">{vessel.number}</Text>
                      </View>
                    )}
                  </View>
                </View>
              )}
            />
          )}
          {isInlineCreate && (
            <Button
              onPress={async () => {
                const result = await submitVessel();
                if (result) {
                  handleVesselCreatedInline(result);
                  setIsInlineCreate(!isInlineCreate);
                }
              }}
              accessoryLeft={(props) => <Icon icon={faPlusCircle} />}
            >
              Submit
            </Button>
          )}
          <Button
            onPress={() => {
              setIsInlineCreate(!isInlineCreate);
              resetVessel();
            }}
            style={{ marginBottom: 12 }}
            appearance="ghost"
            accessoryLeft={(props) =>
              isInlineCreate ? (
                <Icon icon={faAngleLeft} />
              ) : (
                <Icon icon={faPlus} />
              )
            }
          >
            {isInlineCreate ? "Go Back" : "Add New Vessel"}
          </Button>
        </KeyboardAvoidingView>
      </Modal>
      <CancelConfirmationModal />
      <DeleteConfirmationModal />
    </Layout>
  );
};
