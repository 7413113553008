import { v4 as uuid } from "uuid";
import moment from "moment";
import { useState } from "react";
import { useNavigation } from "@react-navigation/native";

import { useAuth, useUser } from "../context/authProvider";
import { useStations } from "../context/stationsProvider";
import {
  useApi,
  StagedDelivery,
  StagedDeliveryItem,
  Fisher,
  Species,
  SpeciesQuantity,
  Vessel,
} from "../api";
import { request } from "@tracktile/axiom";
import Toast from "react-native-root-toast";

import { useStorage } from "../lib/storage";
import { getConfig } from "../config";

export const useLanding = (landingId?: string) => {
  const api = useApi();
  const apiUrl = getConfig().api;
  const navigation = useNavigation();
  const user = useUser();
  const { currentStation } = useStations();
  const { tenant } = useAuth();
  const [token] = useStorage<string>("token");
  const toastOptions = {
    duration: Toast.durations.LONG,
    position: -150,
  };

  const [hasLandingChanged, setHasLandingChanged] = useState<boolean>(false);

  let existingLandingData = api.StagedDelivery.read(landingId ?? "");

  if (!!existingLandingData) {
    existingLandingData = {
      ...existingLandingData,
      isNew: false,
    };
  }

  const [landing, setLandingState] = useState<StagedDelivery>({
    id: uuid(),
    isNew: true,
    date: new Date().toISOString(),
    batchNumber: moment().format("MMMDDYY"),
    slipNumber: "",
    slipDate: moment().format("Y-MM-DD").toString(),
    fishers: [],
    items: [],
    vessel: undefined,
    user,
    locationKey: "", // location.key,
    buyingStation: currentStation,
    createdAt: Date.now(),
    updatedAt: Date.now(),
    signatureId: "",
    syncedToBackend: false,
    syncedToSBM: false,
    ...existingLandingData,
  });

  const [isLoading, setLoadingState] = useState(false);

  const setLanding = (stateFn: (state: StagedDelivery) => StagedDelivery) => {
    setLandingState(stateFn(landing));
    setHasLandingChanged(true);
  };

  const {
    date,
    items: landingItems,
    vessel,
    fishers,
    batchNumber,
    slipNumber,
    buyingStation,
    syncedToSBM,
  } = landing;

  const setDate = (date: Date) => {
    setLanding((landing) => ({ ...landing, date: date.toISOString() }));
  };

  const addFisher = (fisher: Fisher) => {
    setLanding((landing) => ({
      ...landing,
      fishers: [...landing.fishers, fisher],
    }));
  };

  const updateFisher = (fisherId: string, fisher: Fisher) => {
    setLanding((landing) => ({
      ...landing,
      fishers: landing.fishers.map((f) =>
        String(f.id) === String(fisherId) ? fisher : f
      ),
    }));
  };

  const removeFisher = (fisher: Fisher) => {
    setLanding((landing) => ({
      ...landing,
      fishers: landing.fishers.filter(
        ({ id }) => String(id) !== String(fisher.id)
      ),
    }));
  };

  const setBatchNumber = (batchNumber: string) => {
    setLanding((landing) => ({ ...landing, batchNumber }));
  };

  const setSlipNumber = (slipNumber: string) => {
    setLanding((landing) => ({ ...landing, slipNumber }));
  };

  const setSyncedToSBM = (syncedToSBM: boolean) => {
    setLanding((landing) => ({ ...landing, syncedToSBM }));
  };

  const setLandingItems = (items: StagedDeliveryItem[]) =>
    setLanding((landing) => ({
      ...landing,
      items,
    }));

  const setSpeciesForItem = (
    species: Pick<Species, "id" | "name">,
    itemId: string
  ) => {
    const item = landingItems.find(({ id }) => id === itemId);
    if (!item) return;
    setLandingItems([
      ...landingItems.filter(({ id }) => id !== itemId),
      { ...item, species },
    ]);
  };

  const setMetadataForItem = (
    metadata: StagedDeliveryItem["metadata"],
    itemId: string
  ) => {
    const item = landingItems.find(({ id }) => id === itemId);
    if (!item) return;
    setLandingItems([
      ...landingItems.filter(({ id }) => id !== itemId),
      { ...item, metadata },
    ]);
  };

  const setVessel = (vessel: Vessel) => {
    setLanding((landing) => ({
      ...landing,
      vessel,
    }));
  };

  const removeVessel = () => {
    setLanding((landing) => ({
      ...landing,
      vessel: undefined,
    }));
  };

  const { mutate: createLandingMutation } = api.StagedDelivery.create();

  const { mutate: updateLandingMutation } = api.StagedDelivery.update({
    onSuccess: () => {
      api.StagedDelivery.invalidateAll();
    },
  });

  const { mutateAsync: deleteLandingMutation } = api.StagedDelivery.remove();

  const setCountForItem = (count: number, itemId: string) => {
    let newItems = landingItems.map((landingItem) => {
      if (landingItem.id !== itemId) {
        return landingItem;
      }

      return {
        ...landingItem,
        count,
      };
    });

    setLandingItems(newItems);
  };

  const addItem = (species: Species, quantity?: SpeciesQuantity) => {
    setLandingItems([
      ...landingItems,
      {
        id: uuid(),
        species: {
          id: species.id,
          name: species.name,
        },
        metadata: {},
        position: landingItems.length,
        count: 1,
      },
    ]);
  };

  const removeItem = (itemId: string) => {
    setLandingItems([...landingItems.filter(({ id }) => id !== itemId)]);
  };

  const resetLanding = () => {
    setLanding(() => ({
      id: uuid(),
      isNew: true,
      date: new Date().toISOString(),
      fishers: [],
      items: [],
      batchNumber: moment().format("MMMDDYY"),
      slipNumber: "",
      locationKey: "", //location.key,
      user,
      createdAt: Date.now(),
      updatedAt: Date.now(),
      signatureId: "",
      syncedToBackend: false,
      syncedToSBM: false,
    }));
  };

  const submitLanding = async () => {
    if (landing.isNew) {
      createLandingMutation(landing);
      Toast.show("Landing added succesfully", toastOptions);
      navigation.navigate("Landings");
      resetLanding();
    } else {
      updateLandingMutation(landing);
      Toast.show("Landing updated succesfully", toastOptions);
      navigation.navigate("Landings");
    }
  };

  const deleteLanding = async () => {
    await deleteLandingMutation(landing);
    Toast.show("Landing removed succesfully", toastOptions);
    resetLanding();
  };

  const syncLandingToSBM = async (id: string) => {
    await new Promise((resolve, reject) => {
      request(`${apiUrl}/deliveries/staged/${id}/submit`, {
        method: "POST",
        token,
      })
        .then((res) => {
          Toast.show("Landing synced to SBM succesfully", toastOptions);
          api.StagedDelivery.invalidateAll();
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const syncMultipleLandingsToSBM = async (deliveryIds: String[]) => {
    await new Promise((resolve, reject) => {
      request(`${apiUrl}/deliveries/sync-multiple`, {
        method: "POST",
        body: { deliveryIds },
        token,
      })
        .then((res) => {
          Toast.show(
            "Multple landings synced to SBM succesfully",
            toastOptions
          );
          api.StagedDelivery.invalidateAll();
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const getFishersFromVessel = async (vessel: Vessel) => {
    if (vessel.id) {
      try {
        const [fishers] = await request<Fisher[]>(
          `${apiUrl}/fishers/vessel/${vessel.id}`
        );
        setLanding((landing) => ({
          ...landing,
          fishers,
          vessel,
        }));
      } catch (err) {
        Toast.show(
          "Getting fishers from vessel failed. Something went wrong.",
          toastOptions
        );
      }
    } else {
      setLanding((landing) => ({
        ...landing,
        fishers: [],
        vessel,
      }));
    }
  };

  const canCheckout =
    ((slipNumber || tenant?.account?.tunaFunctionality) &&
      !!(
        tenant?.account?.tunaFunctionality &&
        landingItems.every(
          ({ metadata }) =>
            typeof metadata.tagNumber === "string" &&
            `${metadata.tagNumber}`.length > 0
        )
      ) &&
      hasLandingChanged &&
      landingItems.length > 0 &&
      !!fishers?.length) ??
    0 > 0;

  const canCancelLanding = landingId
    ? hasLandingChanged
    : (landingItems.length > 0 || !!fishers?.length) ?? 0 > 0;

  return {
    //Methods
    setDate,
    addFisher,
    updateFisher,
    removeFisher,
    setSpeciesForItem,
    setCountForItem,
    setBatchNumber,
    setSlipNumber,
    setMetadataForItem,
    setSyncedToSBM,
    addItem,
    removeItem,
    setVessel,
    removeVessel,
    resetLanding,
    submitLanding,
    deleteLanding,
    setLoadingState,
    syncLandingToSBM,
    syncMultipleLandingsToSBM,
    getFishersFromVessel,
    //States
    date,
    fishers,
    items: landingItems,
    vessel,
    batchNumber,
    slipNumber,
    buyingStation,
    isLoading,
    syncedToSBM,
    //Conditions
    canCheckout,
    canCancelLanding,
  };
};
