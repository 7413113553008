import { ScrollView } from "react-native";
import { Layout, Button } from "@ui-kitten/components";

import { RootScreenProps } from "../nav";
import { TopNavigation } from "../components";
import { useStations } from "../context/stationsProvider";
import Toast from "react-native-root-toast";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { LandingsStackParamsList } from "./landings";

export function StationSelectionScreen({
  route,
  navigation,
}: NativeStackScreenProps<LandingsStackParamsList>) {
  const { stations, currentStation, setCurrentStation } = useStations();
  const toastOptions = {
    duration: Toast.durations.LONG,
    position: -150,
  };
  return (
    <>
      <TopNavigation
        navigation={navigation}
        title="Change Station"
        enableBackAction
      />
      <Layout style={{ paddingHorizontal: 72, flex: 1 }}>
        <ScrollView>
          {stations.map((station) => (
            <Button
              key={station.id}
              disabled={station.name === currentStation?.name}
              size="giant"
              style={{ marginVertical: 18 }}
              onPress={() => {
                setCurrentStation(station);
                Toast.show(`Station changed to ${station.name}`, toastOptions);
                navigation.navigate("Landings");
              }}
            >
              {station.name}
            </Button>
          ))}
        </ScrollView>
      </Layout>
    </>
  );
}
