import "react-native-get-random-values";
import { useMemo } from "react";
import { Platform, LogBox } from "react-native";
import * as eva from "@eva-design/eva";
import ErrorBoundary from "react-native-error-boundary";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { RootSiblingParent } from "react-native-root-siblings";
import "setimmediate";

import {
  ApplicationProvider,
  Layout,
  IconRegistry,
} from "@ui-kitten/components";

import useColorScheme from "./hooks/useColorScheme";
import useCachedResources from "./hooks/useCachedResources";

import { QueryClientProvider } from "./context/queryClientProvider";
import { AuthProvider } from "./context/authProvider";
import { BaitLocationProvider } from "./context/baitLocationProvider";
import { NetworkContextProvider } from "./context/networkProvider";
import { SyncContextProvider } from "./context/syncProvider";
import { AppLoaderProvider } from "./context/appLoaderProvider";

import {
  AdminScreen,
  BaitLocationSelectionScreen,
  BaitOrderNavigator,
  LandingsNavigator,
  StationSelectionScreen,
} from "./screens";
import { Logout } from "./nav";

import { useAuth } from "./context/authProvider";

import { ApplicationNavigator, TabDescriptor } from "./nav";
import theme from "./theme.json";
import { StationsProvider } from "./context/stationsProvider";
import { registerRootComponent } from "expo";
import { TenantLabelProvider } from "./context/tenantLabelsProvider";
import { StoragePreloader } from "./lib/storage";
import {
  faClose,
  faCorner,
  faGear,
  faInbox,
  faMapPin,
} from "@fortawesome/pro-regular-svg-icons";

if (Platform.OS !== "web") {
  const defaultErrorHandler = ErrorUtils.getGlobalHandler();

  function errorHandler(error: any, isFatal?: boolean) {
    console.error({ error, isFatal });
    defaultErrorHandler(error, isFatal);
  }

  ErrorUtils.setGlobalHandler(errorHandler);
}

LogBox.ignoreAllLogs();

const AuthenticatedApp = () => {
  const { tenant } = useAuth();
  const tabs: TabDescriptor[] = useMemo(
    () =>
      [
        {
          title: Platform.OS === "web" ? "Orders" : "Sell Bait",
          name: "Orders",
          // icon: "inbox-outline",
          icon: faInbox,
          Component: BaitOrderNavigator,
          enabled: !!tenant?.baitOrders?.enabled,
        },
        {
          title: "Landings",
          name: "Landings",
          // icon: "inbox-outline",
          icon: faInbox,
          Component: LandingsNavigator,
          enabled: !!tenant?.deliveries?.enabled,
        },
        {
          title: "Change Location",
          name: "Change Location",
          // icon: "pin-outline",
          icon: faMapPin,
          Component: BaitLocationSelectionScreen,
          enabled: Platform.OS !== "web" && tenant?.baitOrders.enabled,
        },
        {
          title: "Change Station",
          name: "Change Station",
          // icon: "pin-outline",
          icon: faMapPin,
          Component: StationSelectionScreen,
          enabled: Platform.OS !== "web" && tenant?.deliveries?.enabled,
        },
        {
          title: "Admin",
          name: "Admin",
          // icon: "settings-outline",
          icon: faGear,
          Component: AdminScreen,
          enabled: Platform.OS === "web",
        },
        {
          title: "Logout",
          name: "Logout",
          // icon: "log-out-outline",
          icon: faClose,
          Component: Logout,
          enabled: true,
        },
      ].filter(({ enabled }) => !!enabled),
    []
  );

  return (
    <Layout style={{ flex: 1 }}>
      <ApplicationNavigator tabs={tabs} />
    </Layout>
  );
};

function App() {
  const colorScheme = useColorScheme();
  const isLoadingComplete = useCachedResources();

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <SafeAreaProvider>
        <ErrorBoundary>
          <RootSiblingParent>
            <ApplicationProvider
              {...eva}
              theme={{ ...eva[colorScheme], ...theme }}
            >
              <NetworkContextProvider>
                <StoragePreloader>
                  <QueryClientProvider>
                    <AppLoaderProvider>
                      <AuthProvider>
                        <SyncContextProvider>
                          <BaitLocationProvider>
                            <StationsProvider>
                              <TenantLabelProvider>
                                <AuthenticatedApp />
                              </TenantLabelProvider>
                            </StationsProvider>
                          </BaitLocationProvider>
                        </SyncContextProvider>
                      </AuthProvider>
                    </AppLoaderProvider>
                  </QueryClientProvider>
                </StoragePreloader>
              </NetworkContextProvider>
            </ApplicationProvider>
          </RootSiblingParent>
        </ErrorBoundary>
      </SafeAreaProvider>
    );
  }
}

registerRootComponent(App);

export default App;
