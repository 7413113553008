import Constants from "expo-constants";
import { Platform } from "react-native";

type ApplicationRunMode = "wharf" | "office";

function getRunMode(): ApplicationRunMode {
  return Platform.OS === "android" ? "wharf" : "office";
}

const commonConfig = {
  runMode: getRunMode(),
} as const;

const envConfig = {
  local: {
    environment: "local",
    api: "http://localhost:3000",
  },
  prod: {
    environment: "prod",
    api: "https://api.seafaresystems.com",
  },
} as const;

export const getConfig = (env = Constants.manifest?.extra?.env ?? "local") => {
  return {
    ...commonConfig,
    ...(env.toLowerCase() === "local" ? envConfig.local : envConfig.prod),
  };
};
