import { PropsWithChildren } from "react";
import { useIsRestoring } from "@tanstack/react-query";
import { Layout, Spinner, Text } from "@ui-kitten/components";

import { useStoragePreload } from "../lib/storage";

export const AppLoaderProvider = ({ children }: PropsWithChildren<{}>) => {
  const isRestoring = useIsRestoring();
  const { isReady } = useStoragePreload();

  if (!isReady || isRestoring) {
    return (
      <Layout
        style={{
          flexGrow: 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner size="large" />
        <Text category="S1" style={{ marginVertical: 24 }}>
          Going Fishing...
        </Text>
      </Layout>
    );
  }

  return <>{children}</>;
};
