import React, { useState, FC } from "react";
import {
  Button,
  Card,
  Modal as KittenModal,
  Text,
  Layout,
  StyleService,
  useStyleSheet,
} from "@ui-kitten/components";

const themedStyles = StyleService.create({
  container: {
    minHeight: 192,
    width: "100%",
  },
  backdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
});

interface UseConfirmationParams {
  title: string;
  description: string;
  confirmText: string;
  confirmStatus: "primary" | "danger";
  cancelText: string;
  cancelStatus: "primary" | "danger";
  onConfirm: () => void;
  onCancel: () => void;
}

interface UseConfirmationState {
  show: (params?: UseConfirmationParams) => void;
  hide: () => void;
  toggle: () => void;
  Modal: FC;
}

const DEFAULT_CONFIRMATION_PARAMS = {
  title: "Are You Sure?",
  description: "This cannot be undone.",
  confirmText: "Confirm",
  confirmStatus: "primary",
  cancelText: "Cancel",
  cancelStatus: "danger",
  onConfirm: () => {},
  onCancel: () => {},
} as UseConfirmationParams;

export const useConfirmation = (
  params: Partial<UseConfirmationParams> = DEFAULT_CONFIRMATION_PARAMS
): UseConfirmationState => {
  const { title, description, confirmText, cancelText, onConfirm, onCancel } = {
    ...DEFAULT_CONFIRMATION_PARAMS,
    ...params,
  };
  const styles = useStyleSheet(themedStyles);
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);
  const toggle = () => setVisible(!visible);

  const handleCancel = () => {
    onCancel();
    hide();
  };

  const handleConfirm = () => {
    onConfirm();
    hide();
  };

  const Modal: FC = () => (
    <KittenModal
      visible={visible}
      backdropStyle={styles.backdrop}
      onBackdropPress={() => setVisible(false)}
    >
      <Card>
        <Layout>
          <Text category="h4">{title}</Text>
        </Layout>
        <Layout style={{ marginVertical: 18 }}>
          <Text category="s1">{description}</Text>
        </Layout>
        <Layout
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Button style={{ marginRight: 20 }} onPress={() => handleCancel()}>
            {cancelText}
          </Button>
          <Button status="danger" onPress={() => handleConfirm()}>
            {confirmText}
          </Button>
        </Layout>
      </Card>
    </KittenModal>
  );

  return { show, hide, toggle, Modal };
};
